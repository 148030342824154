import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../paradoxBuilder.css"
import "./paradoxSp.css"
import { fetchParadoxProduct, updateParadoxProduct } from "services/paradoxServices";
import { useParams } from "react-router-dom";
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";
import { fetchStructuredProducts } from "services/structuredProductsServices";

const ParadoxSp = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [searchedSp, setSearchedSp] = useState([]);
  const [search, setSearch] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateParadoxProduct(user, id, product);
      navigate(`/paradox`)

    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchParadoxProduct(user, id);
      setProduct(response)
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  const getYieldFrequencyText = (frequency) => {
    switch (frequency) {
      case "monthly":
        return "Mensuel";
      case "annualy":
        return "Annuel";
      case "quarterly":
        return "Trimestriel";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleSearchSp = async (e) => {
    try {
      setSearch(e.target.value)
      const response = await fetchStructuredProducts({ user, page: 0, limit: 10, search: e.target.value, })
      setSearchedSp(response)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectSp = (selectedSp) => {
    setSearch('')
    setProduct({
      ...product,
      structured_product: selectedSp
    });
  }


  const handleRemoveSp = () => {
    const updatedProduct = { ...product };
    delete updatedProduct.structured_product;
    setProduct(updatedProduct);
  }



  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="select-ticker-flex">
                <div className="paradox_sp_search_box">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Produits associés :</span>
                    <input className="paradox_sp_search_input_box" type="text" name="products" placeholder="Rechercher un produit structuré" onChange={handleSearchSp} />
                  </div >
                  {
                    searchedSp.length > 0 && search.length > 0 && (
                      <div className="ticker-results">
                        {searchedSp.map((item, index) => (
                          <div key={index} className="psform-ticker-box pointer" onClick={() => handleSelectSp(item)}>
                            <span className="psform-input-label">{item.name}</span>
                          </div >
                        ))}
                      </div>
                    )
                  }
                </div>
                {product.structured_product &&
                  <div className="paradox_sp_details_box">
                    <p className="paradox_sp_delete_product_button" onClick={handleRemoveSp}>x</p>
                    <h2 className="paradox_sp_details_title">{product.structured_product?.name}</h2>

                    <div className="paradox_sp_box">
                      <div className="paradox_sp_column">
                        <div className="recap-first-grid-item">
                          <p className="no-padding recap-first-grid-title">Rendements</p>
                          <p className="no-padding recap-first-grid-data">{product.structured_product?.yield}%</p>
                        </div>
                        <div className="recap-first-grid-item">
                          <p className="no-padding recap-first-grid-title">Versement</p>
                          <p className="no-padding recap-first-grid-data">{getYieldFrequencyText(product.structured_product?.yield_frequency)}</p>
                        </div>
                      </div>

                      <div className="paradox_sp_column">
                        <div className="recap-first-grid-item">
                          <p className="no-padding recap-first-grid-title">Barrière</p>
                          <p className="no-padding recap-first-grid-data">{product.structured_product?.barrier_capital}%</p>
                        </div>
                        <div className="recap-first-grid-item">
                          <p className="no-padding recap-first-grid-title">Durée</p>
                          <p className="no-padding recap-first-grid-data">{product.structured_product?.maturity} mois</p>
                        </div>
                      </div>
                    </div>

                    <div className="paradox_sp_selected_product_composition_box">
                      {
                        product.structured_product?.products.map((item, index) => (
                          <div key={index} className="psform-ticker-selected-box pointer">
                            <div className="ticker-desc">
                              <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${item.ticker}.png`} alt="" />
                              <span className="psform-input-label">{item.name}</span>
                            </div>
                          </div >
                        ))
                      }
                    </div>

                  </div>
                }
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout>
  )
}

export default ParadoxSp