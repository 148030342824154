import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { fetchTickers } from "services/tickersServices"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../paradoxBuilder.css"
import { fetchSpDraft, saveSpDraft } from "services/spDraftServices"
import { fetchParadoxProduct, updateParadoxProduct } from "services/paradoxServices";
import { useParams } from "react-router-dom";
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";

const ParadoxStocks = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [searchedTickers, setSearchedTickers] = useState([]);
  const [search, setSearch] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateParadoxProduct(user, id, product);
      navigate(`/paradox/${id}/playlists`)

    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchParadoxProduct(user, id);
      setProduct({
        ...response,
        product_type: response.product_type || 'worst-of'
      })
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])


  const handleSearchTicker = async (e) => {
    try {
      setSearch(e.target.value)
      const response = await fetchTickers({ user, page: 0, limit: 10, search: e.target.value, leverage: false })
      setSearchedTickers(response)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectTicker = (selectedStock) => {
    setSearch('')
    const currentProducts = product.stocks ? [...product.stocks] : [];
    if (!currentProducts.some(({ ticker }) => ticker === selectedStock.ticker)) {
      currentProducts.push(selectedStock);
    }
    setProduct({
      ...product,
      stocks: currentProducts
    });
  }


  const handleRemoveTicker = (tickerSymbol) => {
    const updatedProducts = product.stocks.filter(({ ticker }) => ticker !== tickerSymbol);
    setProduct({
      ...product,
      stocks: updatedProducts
    });
  }



  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="select-ticker-flex">
                <div className="paradox_stocks_search_box">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Produits associés :</span>
                    <input className="paradox_stocks_search_input_box" type="text" name="products" placeholder="Rechercher un ticker" onChange={handleSearchTicker} value={search} />
                  </div >
                  {
                    searchedTickers.length > 0 && search.length > 0 && (
                      <div className="ticker-results">
                        {searchedTickers.map((stock, index) => (
                          <div key={index} className="psform-ticker-box pointer" onClick={() => handleSelectTicker(stock)}>
                            <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${stock.ticker}.png`} alt="" />
                            <span className="psform-input-label">{stock.name}</span>
                          </div >
                        ))}
                      </div>
                    )
                  }
                </div>
                <div className="paradox_stocks_details_box">
                  {
                    product?.stocks?.map((stock, index) => (
                      <div key={index} className="psform-ticker-selected-box pointer">
                        <div className="ticker-desc">
                          <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${stock.ticker}.png`} alt="" />
                          <div className="paradox_stocks_selected_item">
                            <span className="psform-input-label">{stock.name}</span>
                            <span className="psform-input-label-text-small">{stock.ticker}</span>
                          </div>
                        </div>
                        <span onClick={() => handleRemoveTicker(stock.ticker)} className="pointer delete-ticker-button">supprimer</span>
                      </div >
                    ))
                  }
                </div>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout>
  )
}

export default ParadoxStocks