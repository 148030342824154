import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { searchEtfs } from "services/tickersServices"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../paradoxBuilder.css"
import { fetchParadoxProduct, updateParadoxProduct } from "services/paradoxServices";
import { useParams } from "react-router-dom";
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";

const ParadoxEtfs = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [searchedTickers, setSearchedTickers] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateParadoxProduct(user, id, product);
      navigate(`/paradox/${id}/structured-product`)

    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchParadoxProduct(user, id);
      setProduct({
        ...response,
        product_type: response.product_type || 'worst-of'
      })
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleSearchEtf = async (e) => {
    try {
      const response = await searchEtfs(user, e.target.value)
      setSearchedTickers(response)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectEtf = (selectedEtf) => {
    const currentEtfs = product.etf ? [...product.etf] : [];
    if (!currentEtfs.some(ticker => ticker === selectedEtf)) {
      currentEtfs.push(selectedEtf.ticker);
    }
    setProduct({
      ...product,
      etf: currentEtfs
    });
  }


  const handleRemoveTicker = (etfSymbol) => {
    const updatedEtfs = product.etf.filter(etf => etf !== etfSymbol);
    setProduct({
      ...product,
      etf: updatedEtfs
    });
  }



  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="select-ticker-flex">
                <div className="select-ticker-search">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Produits associés :</span>
                    <input className="psform-input-text" type="text" name="products" placeholder="Rechercher un ETF" onChange={handleSearchEtf} />
                  </div >
                  {
                    searchedTickers.length > 0 && (
                      <div className="ticker-results">
                        {searchedTickers.map((ticker, index) => (
                          <div key={index} className="psform-ticker-box pointer" onClick={() => handleSelectEtf(ticker)}>
                            <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} alt="" />
                            <span className="psform-input-label">{ticker.ticker} | {ticker.isin}</span>
                          </div >
                        ))}
                      </div>
                    )
                  }
                </div>
                <div className="select-ticker-product">
                  {
                    product.etf.map((etf, index) => (
                      <div key={index} className="psform-ticker-selected-box pointer">
                        <div className="ticker-desc">
                          <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${etf}.png`} alt="" />
                          <span className="psform-input-label">{etf}</span>
                        </div>
                        <span onClick={() => handleRemoveTicker(etf)} className="pointer delete-ticker-button">supprimer</span>
                      </div >
                    ))
                  }
                </div>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout>
  )
}

export default ParadoxEtfs