import React, { useState, useEffect, useContext } from 'react'
import AuthContext from "contexte/AuthContext"
import './complianceReportsAgbk.css'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import { RELEASE_DATE, getDateFilenameFormatted } from 'utils/date';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';



const ComplianceReportsAgbk = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <DashboardLayout page={t("pages_name.agbk_report_compliance")}>
      <Notification />
      <div className='externalDeposit_titleComponent'>
        <h2>{t("pages.agbk_report_compliance.title")}</h2>

      </div>
      <div>
        <ul>
          <a href='/reports/agbk-compliance/best-execution'>{t('pages.agbk_report_compliance.best_execution_link')}</a>
          {/* <a href='/reports/agbk-compliance/users-traded'>{t('pages.agbk_report_compliance.users_traded_link')}</a> */}
          <a href='/reports/agbk-compliance/economic-profile'>{t('pages.agbk_report_compliance.economic_profile_link')}</a>
          <a href='/reports/agbk-compliance/governance'>{t('pages.agbk_report_compliance.governance_link')}</a>
          <a href='/reports/agbk-compliance/transactions-monitoring'>{t('pages.agbk_report_compliance.transactions_monitoring_link')}</a>
          <a href='/reports/agbk-compliance/users-cumulative-expenses'>{t('pages.agbk_report_compliance.cumulative_expenses_link')}</a>
          <a href='/reports/agbk-compliance/funds-movements'>{t('pages.report_accounts.agbk_funds_movements_link')}</a>
          <a href='/reports/agbk-compliance/sumsub'>{t('pages.agbk_report_compliance.user_sumsub_link')}</a>

        </ul>
      </div>
    </DashboardLayout>
  )
}

export default ComplianceReportsAgbk
