import axios from "axios";
import { AGO_API_BASE_URL, API_BASE_URL, buildHeaders } from "utils/services";

export const fetchSubscriptionsKpiDataForDates = async (user, selectedDate, comparedDate) => {
  let service = fetchParadoxSubscriptionDataForDate;
  let default_datas = getDefaultParadoxSubscriptionKpiData;
  try {
    const [selectedData, comparedData] = await Promise.all([
      service(user, selectedDate),
      service(user, comparedDate),
    ]);
    return { selectedData, comparedData };
  } catch (error) {
    console.error("Error fetching KPI data for dates:", error);
    return {
      selectedData: default_datas(),
      comparedData: default_datas(),
    };
  }
};


const getDefaultParadoxSubscriptionKpiData = () => ({
  subscriptions: 0,
  unsubscriptions: 0,
  new: 0,
  yearly: 0,
  mrr: 0
});


const fetchParadoxSubscriptionDataForDate = async (user, date) => {
  try {
    if (date.startDate === null || date.endDate === null) {
      return getDefaultParadoxSubscriptionKpiData();
    }

    const params = `?startDate=${date.startDate}&endDate=${date.endDate}`

    const [
      { data: count },
      { data: countNew },
      { data: countYearly },
      { data: countUnsubscribe },
      { data: mrr },
    ] = await Promise.all([
      axios.get(`${API_BASE_URL}/subscriptions/count${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/subscriptions/count/new${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/subscriptions/count/yearly${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/subscriptions/count/unsubscribe${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/subscriptions/compute-mrr${params}`, buildHeaders(user))
    ]);


    const kpiData = {
      subscriptions: count,
      unsubscriptions: countUnsubscribe,
      new: countNew,
      yearly: countYearly,
      mrr
    };

    return kpiData;
  } catch (error) {
    console.error("Error fetching Paradox KPI data:", error);
    return getDefaultParadoxSubscriptionKpiData();
  }
};