export const sidebarMenu = [
    {
        name: "accueil",
        linkTo: "/",
        icon: "/img/menu/home.svg",
        subMenu: []
    },
    {
        name: "finances",
        linkTo: "/finances",
        icon: "/img/menu/money.svg",
        subMenu: [
            {
                name: "comptes",
                linkTo: "/finances/accounts",
            },
            {
                name: "comptes_ibanfirst",
                linkTo: "/finances/accounts-ibanfirst",
            },
            {
                name: "payouts",
                linkTo: "/finances/payouts",
            },
            {
                name: "depots_externes",
                linkTo: "/finances/external-deposit",
            },
            {
                name: "iban",
                linkTo: "/finances/iban",
            },
            {
                name: "transactions",
                linkTo: "/finances/trades",
            },
            {
                name: "ordres",
                linkTo: "/finances/orders",
            },
            {
                name: "problemes",
                linkTo: "/finances/problems",
            },
            {
                name: "manual_payout",
                linkTo: '/finances/manual-payout'
            },
            {
                name: "investment_plans",
                linkTo: '/finances/investment-plans'
            },
            {
                name: "structured_products",
                linkTo: '/finances/structured-products'
            },
            {
                name: "features",
                linkTo: '/finances/features'
            },
            {
                name: "margin_transactions",
                linkTo: '/finances/margin-transactions'
            },
            {
                name: "utility_bills",
                linkTo: "/finances/utility-bills",
            }
        ]
    },
    {
        name: "structured",
        linkTo: "/structured-products",
        icon: "/img/menu/posts.svg",
        subMenu: []
    },
    {
        name: "cadeaux",
        linkTo: "/gifts",
        icon: "/img/menu/gifts.svg",
        subMenu: [
            {
                name: 'gifts',
                linkTo: '/gifts/list'
            },
            {
                name: 'gift',
                linkTo: '/gifts/new'
            },
            {
                name: 'discount',
                linkTo: '/gifts/discount'
            }
        ]
    },
    {
        name: "actifs",
        linkTo: "/actifs",
        icon: "/img/menu/finances.svg",
        subMenu: [
            {
                name: "alpaca",
                linkTo: "/actifs/alpaca",
            },
            {
                name: "truelayer",
                linkTo: "/actifs/truelayer",
            },
            {
                name: "categories",
                linkTo: "/actifs/categories",
            },
            {
                name: "tickers",
                linkTo: "/actifs/tickers",
            },
            {
                name: "new_ticker",
                linkTo: "/actifs/tickers/new",
            },
            {
                name: "add_margin_ticker",
                linkTo: "/actifs/tickers/add_margin",
            },
            {
                name: "tickers_non_trouves",
                linkTo: "/actifs/tickers/not-found",
            },
            {
                name: "etf_edition",
                linkTo: "/actifs/etf/edit",
            },
            {
                name: "reconciliations",
                linkTo: "/actifs/reconciliations",
            },
        ]
    },
    {
        name: "users",
        linkTo: "/users",
        icon: "/img/menu/usersIcon.svg",
        subMenu: [
            {
                name: "users_list",
                linkTo: "/users/list",
            },
            {
                name: "analytics",
                linkTo: "/users/analytics",
            },
            {
                name: "creators",
                linkTo: "/users/creators",
            },
            {
                name: "users_to_call",
                linkTo: "/users/call/list",
            },
            {
                name: "deleted_users",
                linkTo: "/users/deleted/list",
            },
            {
                name: "user_notify",
                linkTo: "/users/notify",
            },
        ]
    },
    {
        name: "ago",
        icon: "/img/menu/usersIcon.svg",
        subMenu: [
            {
                name: "ago_users_list",
                linkTo: "/ago/users",
            },
            {
                name: "ago_deposits",
                linkTo: "/ago/deposits",
            },
            {
                name: "ago_payouts",
                linkTo: "/ago/payouts",
            },
            {
                name: "ago_transactions",
                linkTo: "/ago/transactions",
            }

        ]

    },
    {
        name: "posts",
        linkTo: "/posts",
        icon: "/img/menu/posts.svg",
        subMenu: []
    },
    {
        name: "paradox",
        linkTo: "/paradox",
        icon: "/img/menu/posts.svg",
        subMenu: []
    },
    {
        name: "rapports",
        linkTo: "/reports",
        icon: "/img/menu/reports.svg",
        subMenu: [
            {
                name: "users",
                linkTo: "/reports/users",
            },
            {
                name: "trades",
                linkTo: "/reports/trades",
            },
            {
                name: "money",
                linkTo: "/reports/money",
            },
            {
                name: "compliance",
                linkTo: "/reports/compliance",
            },
            {
                name: "agbk_reports",
                linkTo: "/reports/agbk-compliance",
            },
            {
                name: "accounts",
                linkTo: "/reports/accounts",
            },
            {
                name: "sumsub",
                linkTo: "/reports/sumsub-screening",
            },
            {
                name: "transactions_reporting",
                linkTo: "/reports/trs",
            }
        ]
    },
    {
        name: "queues",
        linkTo: "/queues",
        icon: "/img/menu/reports.svg",
        subMenu: [
            {
                name: "dlq",
                linkTo: "/queues/dlq",
            },
            {
                name: "nta",
                linkTo: "/queues/nta",
            }
        ]
    },
]