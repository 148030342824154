import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import { useTranslation } from 'react-i18next'
import "./playlistBuilder.css"
import { useContext, useEffect, useState } from "react";
import AuthContext from "contexte/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "components/pageLoader/PageLoader";
import { fetchTickers } from "services/tickersServices";
import { createPlaylist, fetchPlaylist, updatePlaylist } from "services/playlistServices";

const colors = ["#3B28CC", "#F3A712", "#A270FF", "#FF3A20", "#9BC53D", "#0EB1D2"]


const PlaylistBuilder = () => {

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams()

  const [loading, setLoading] = useState(!!id);
  const [error, setError] = useState();
  const [searchedTickers, setSearchedTickers] = useState([]);
  const [search, setSearch] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [playlist, setPlaylist] = useState({
    name: '',
    color: colors[Math.floor(Math.random() * colors.length)],
    tickers: []
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id)
        await updatePlaylist(user, id, playlist);
      else
        await createPlaylist(user, playlist)
      navigate('/playlists')
    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const playlist = await fetchPlaylist(user, id);
      setPlaylist(playlist)
      computePercentages(playlist.tickers)
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    if (id)
      fetchActualData();
  }, [])


  const handleSearchTicker = async (e) => {
    try {
      setSearch(e.target.value)
      const response = await fetchTickers({ user, page: 0, limit: 10, search: e.target.value, type: 'us_equity' })
      setSearchedTickers(response)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectTicker = (selectedStock) => {
    if (playlist.tickers.length < 25) {
      setSearch('')
      const updatedTickers = [...playlist.tickers];
      if (!updatedTickers.some(({ ticker }) => ticker === selectedStock.ticker)) {
        updatedTickers.push({ ...selectedStock, percentage: 0 });
      }
      setPlaylist({ ...playlist, tickers: updatedTickers });
    }

  }

  const handleRemoveTicker = (tickerSymbol) => {
    const updatedTickers = [...playlist.tickers].filter(({ ticker }) => ticker !== tickerSymbol);
    setPlaylist({ ...playlist, tickers: updatedTickers });
    computePercentages(updatedTickers)
  }

  const handlePercentage = (selectedTicker, percentage) => {
    const updatedTickers = [...playlist.tickers];
    const index = updatedTickers.findIndex(({ ticker }) => ticker === selectedTicker);
    updatedTickers[index].percentage = Number(percentage)
    setPlaylist({ ...playlist, tickers: updatedTickers });
    computePercentages(updatedTickers)
  }

  const computePercentages = (tickers) => setPercentage(tickers.reduce((acc, { percentage }) => acc + percentage, 0))


  const handleInputChange = (e, fieldName) => {
    let name = e?.target?.name || fieldName;
    let value = e?.target?.value || e;

    const updatedPlaylist = { ...playlist };
    if (name.includes('.')) {
      const splits = name.split('.');
      const firstLevel = { ...playlist[splits[0]] };

      let secondLevel = value;
      if (splits.length === 3) {
        secondLevel = { ...firstLevel[splits[1]] };
        secondLevel[splits[2]] = value;
      }
      firstLevel[splits[1]] = secondLevel;

      updatedPlaylist[splits[0]] = firstLevel;
    } else {
      updatedPlaylist[name] = value
    }
    setPlaylist(updatedPlaylist)
  }

  return (
    <DashboardLayout page={t(`pages_name.${id ? 'update_' : 'create_'}playlist`, { name: playlist?.name })}>
      <div className="pslayout-box">
        <div >
          {
            loading ? (
              <div className='pageLoaderBox'>
                <PageLoader />
              </div>
            ) : (
              error ? (
                <div>Erreur</div>
              ) : (
                <form onSubmit={handleSubmit} className="psform">
                  <div className="select-ticker-flex">
                    <div className="playlist_builder_search_box">
                      <div className="psform-input-box">
                        <span className="psform-input-label">Nom de la playlist :</span>
                        <input className="playlist_builder_search_input_box" type="text" name="name" placeholder="Nom de la playlist" value={playlist.name} onChange={handleInputChange} />
                      </div >
                      <div className="psform-input-box">
                        <span className="psform-input-label">Couleur de la playlist :</span>
                        <div className="playlist_builder_color_picker_row">
                          {colors.map(color => (
                            <div
                              key={color}
                              onClick={() => handleInputChange(color, 'color')}
                              className='playlist_builder_color_picker'
                              style={{
                                backgroundColor: color,
                                borderWidth: '5px',
                                borderStyle: 'solid',
                                borderColor: playlist?.color === color ? 'black' : color
                              }}>

                            </div>
                          ))}
                        </div>

                      </div >
                      <div className="psform-input-box">
                        <span className="psform-input-label">Stocks associés :</span>
                        <input className="playlist_builder_search_input_box" type="text" name="products" placeholder="Rechercher un ticker" onChange={handleSearchTicker} value={search} />
                      </div >
                      {
                        searchedTickers.length > 0 && search.length > 0 && (
                          <div className="ticker-results">
                            {searchedTickers.map((stock, index) => (
                              <div key={index} className="psform-ticker-box pointer" onClick={() => handleSelectTicker(stock)}>
                                <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${stock.ticker}.png`} alt="" />
                                <span className="psform-input-label">{stock.name}</span>
                              </div >
                            ))}
                          </div>
                        )
                      }
                    </div>
                    <div className="playlist_builder_details_box">
                      {
                        playlist.tickers.map((stock, index) => (
                          <div key={index} className="psform-ticker-selected-box pointer">
                            <div className="playlist_builder_ticker_desc">
                              <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${stock.ticker}.png`} alt="" />
                              <div className="playlist_builder_selected_item_ticker">
                                <span className="psform-input-label">{stock.name}</span>
                                <span className="psform-input-label-text-small">{stock.ticker}</span>
                              </div>
                            </div>
                            <div className="playlist_builder_ticker_desc_end">

                              <input className="psform-input-text-multi" type="number" name="performance.percentage" value={stock.percentage} onChange={(e) => handlePercentage(stock.ticker, e.target.value)} />
                              <span onClick={() => handleRemoveTicker(stock.ticker)} className="pointer delete-ticker-button">Supprimer</span>
                            </div >
                          </div >
                        ))
                      }
                    </div>
                  </div>
                  {
                    !id && <div className="playlist_builder_submit_box">
                      <span className="playlist_builder_alert_label">{playlist.tickers.length > 0 && percentage !== 100 ? `La somme des pourcentages des tickers est différente de 100% (${percentage})` : ''}</span>
                      <button type="submit" className="playlist_builder_submit" disabled={percentage !== 100}>Créer</button>
                    </div>
                  }

                </form >
              )
            )
          }
        </div>
      </div>
    </ DashboardLayout>
  )
}

export default PlaylistBuilder