import './etfInfosTab.css'
import Notification from 'components/notifications/Notification'


const EtfInfosTab = ({ etf, editEtf }) => {

  const handleInput = (e) => {
    editEtf({ ...etf, [e.target.name]: e.target.value })
  }

  return (
    <div>

      <div className='etf_sectors_list'>
        <Notification />

        <div className='etf_infos_container'>
          <label htmlFor='isin'>ISIN : </label>
          <input className='etf_sector_item_input' type='text' value={etf.isin} id='isin' name='isin' onChange={handleInput} />
        </div>

        <div className='etf_infos_container'>
          <label htmlFor='replication'>Réplication : </label>
          <input className='etf_sector_item_input' type='text' value={etf.replication} id='replication' name='replication' onChange={handleInput} />
        </div>

      </div>

    </div>
  )
}

export default EtfInfosTab