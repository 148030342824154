// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pslayout-box{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: -webkit-fill-available;
  height: 100%;
  background-color: #F2F3FC;
  border-radius: 18px;
  padding: 20px;
}

.pslayout-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.pslayout-header-link{
  text-decoration: none;
  font-weight: bold;
  width: 100%;
}

.pslayout-header-button{
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 10px;
  font-weight: 400;
  width: -webkit-fill-available;
  text-align: center;
}

.pslayout-header-button-selected{
  background-color: #2B64F5;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  border: solid 1px #2B64F5;
  padding: 10px 10px;
  font-weight: bold;
  width: -webkit-fill-available;
  text-align: center;
}

.psform-preview-box{
  width: 200px;
}

.psform-preview-img{
  border-radius: 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/paradox/layout/paradoxLayout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,6BAA6B;EAC7B,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".pslayout-box{\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: -webkit-fill-available;\n  height: 100%;\n  background-color: #F2F3FC;\n  border-radius: 18px;\n  padding: 20px;\n}\n\n.pslayout-header{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 10px;\n}\n\n.pslayout-header-link{\n  text-decoration: none;\n  font-weight: bold;\n  width: 100%;\n}\n\n.pslayout-header-button{\n  background-color: #fff;\n  color: #000;\n  border-radius: 8px;\n  font-size: 14px;\n  padding: 10px 10px;\n  font-weight: 400;\n  width: -webkit-fill-available;\n  text-align: center;\n}\n\n.pslayout-header-button-selected{\n  background-color: #2B64F5;\n  color: white;\n  border-radius: 8px;\n  font-size: 14px;\n  border: solid 1px #2B64F5;\n  padding: 10px 10px;\n  font-weight: bold;\n  width: -webkit-fill-available;\n  text-align: center;\n}\n\n.psform-preview-box{\n  width: 200px;\n}\n\n.psform-preview-img{\n  border-radius: 10px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
