import './etfEditionTabs.css'
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react'
import EtfComponentsTab from './EtfComponentsTab/EtfComponentsTab';
import EtfCountriesTab from './EtfCountriesTab/EtfCountriesTab';
import EtfSectorsTab from './EtfSectorsTab/EtfSectorsTab';
import EtfInfosTab from './EtfInfosTab/EtfInfosTab';

const EtfEditionTabs = ({ etf, editEtf }) => {

    const { t } = useTranslation();

    const [tab, setTab] = useState(0)

    const getComponent = useCallback((t) => {
        switch (t) {
            case 0:
                return <EtfComponentsTab etf={etf} editEtf={editEtf} />
            case 1:
                return <EtfCountriesTab etf={etf} editEtf={editEtf} />
            case 2:
                return <EtfSectorsTab etf={etf} editEtf={editEtf} />
            case 3:
                return <EtfInfosTab etf={etf} editEtf={editEtf} />
            default:
                return null;
        }
    }, [etf])

    return (
        <div>
            <div>
                <div className='etf_edition_switcher'>
                    {['Compositions', 'Pays', 'Secteurs', 'Infos'].map((title, index) =>
                        (<div key={index} className={`etf_edition_tab ${tab === index ? "etf_edition_tab_select" : "etf_edition_tab_not_select"}`} onClick={() => { setTab(index) }}>{title}</div>)
                    )}
                </div>
                {
                    getComponent(tab)
                }
            </div>

        </div>)
}

export default EtfEditionTabs