import { useState, useEffect, useContext } from 'react'
import './paradoxPostId.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexte/AuthContext';
import { archiveParadoxPost, deleteParadoxComment, fetchParadoxPost, unpinParadoxPost, pinParadoxPost } from 'services/postServices';
import { useNavigate } from 'react-router-dom';
import TableUser from 'components/TableUser/TableUser';
import { formatDate } from 'utils/date';
import { launchError, launchSuccess } from 'components/notifications';
import Notification from 'components/notifications/Notification';

const ParadoxPostId = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState({})
  const id = window.location.pathname.split('/')[2]



  const fetchData = async () => {
    try {
      const getPostData = await fetchParadoxPost(user, id)
      setPost(getPostData)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const archiveThisPost = async () => {

    try {
      await archiveParadoxPost(user, post._id)
      navigate('/paradox-posts')
      launchSuccess(t('notifications.post.archive.success'))
    } catch (e) {
      launchError(t('notifications.post.archive.error'))
      console.log(e)
    }

  }

  const pinThisPost = async () => {

    try {
      await pinParadoxPost(user, post._id)
      navigate('/paradox-posts')
      launchSuccess(t('notifications.post.pin.success'))
    } catch (e) {
      launchError(t('notifications.post.pin.error'))
      console.log(e)
    }

  }

  const unpinThisPost = async () => {

    try {
      await unpinParadoxPost(user, post._id)
      navigate('/paradox-posts')
      launchSuccess(t('notifications.post.unpin.success'))
    } catch (e) {
      launchError(t('notifications.post.unpin.error'))
      console.log(e)
    }

  }

  const deletePostComment = async (comment_uuid) => {
    try {
      await deleteParadoxComment({ user, post_uuid: post.getstream_uuid, comment_uuid })
      setPost(prev => {
        const newPost = { ...prev }
        const comments = [...newPost.comments];
        const index = comments.findIndex(comment => comment.comment_uuid === comment_uuid);
        comments.splice(index, 1);
        newPost['comments'] = comments;
        return newPost
      })
      launchSuccess(t('notifications.post.delete.success'))
    } catch (e) {
      launchError(t('notifications.post.delete.error'))
      console.log(e)
    }
  }

  const deleteChildComment = async (comment_uuid, child_uuid) => {
    try {
      await deleteParadoxComment({ user, post_uuid: post.getstream_uuid, comment_uuid: child_uuid })
      setPost(prev => {
        const newPost = { ...prev }
        const comments = [...newPost.comments];
        const commentIndex = comments.findIndex(comment => comment.comment_uuid === comment_uuid);
        const comment = { ...comments[commentIndex] };
        const child_comments = [...comment.child_comments];
        const childIndex = child_comments.findIndex(child => child.child_uuid === child_uuid);
        child_comments.splice(childIndex, 1);
        comment['child_comments'] = child_comments;
        comments[commentIndex] = comment;
        newPost['comments'] = comments;
        return newPost
      })
      launchSuccess(t('notifications.post.delete.success'))
    } catch (e) {
      launchError(t('notifications.post.delete.error'))
      console.log(e)
    }
  }

  return (
    <DashboardLayout page={t("pages_name.post")}>
      <div>
        <h1>Détail du post</h1>
        {!loading ? (
          <div>
            <Notification />
            <div className='post_header_profile'>
              <div className='post_table_user'>
                <div className='post_userInformation_profil_img' style={{ backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/usr/pic/${post.user_uuid})` }} />
                <div className='post_user_detail'>
                  <p className='post_user_name'>{post.firstName} {post.lastName}</p>
                  <span className='post_user_email'>{post.email}</span>
                </div>
              </div>
              <div>
                <span>{post.updatedAt}</span>
              </div>
            </div>

            <div className='post_desc_container'>
              <div className='post_desc_header'>
                <button className='post_archive_button' onClick={archiveThisPost}>Archiver</button>
                <button className='post_pin_button' onClick={pinThisPost} disabled={post.isPinned}>Pin</button>
                <button className='post_unpin_button' onClick={unpinThisPost} disabled={!post.isPinned}>Unpin</button>
              </div>
              <p className='post_content'>{post.desc}</p>
              <div className='post_footer'>{formatDate({ date: post.createdAt, time: true })}</div>
            </div>
            {
              post.comments.length > 0 &&
              <>
                {post.comments.map(comment => (
                  <div className='comments_container' key={comment.comment_uuid}>
                    <div className='comment_desc_container'>
                      <div className='comment_desc_header'>
                        <TableUser
                          fullName={comment.name}
                          picture={comment.user_uuid}
                          user_uuid={comment.user_uuid}
                          key={comment.user_uuid}
                          redirect={true}
                        />
                        <button className='comment_delete_button' onClick={() => deletePostComment(comment.comment_uuid)}>Supprimer</button>
                      </div>
                      <p className='post_content' >{comment.content}</p>
                      <div className='post_footer'>{formatDate({ date: comment.created_at, time: true })}</div>
                    </div>
                    {
                      comment.child_comments.length > 0 && (
                        comment.child_comments.map(child => (
                          <div key={child.child_uuid} className='child_comment_desc_container'>
                            <div className='comment_desc_header'>
                              <TableUser
                                fullName={child.name}
                                picture={child.user_uuid}
                                user_uuid={comment.user_uuid}
                                key={comment.user_uuid}
                                redirect={true}
                              />
                              <button className='comment_delete_button' onClick={() => deleteChildComment(comment.comment_uuid, child.child_uuid)}>Supprimer</button>
                            </div>
                            <p className='post_content'>{child.content}</p>
                            <div className='post_footer'>{formatDate({ date: child.created_at, time: true })}</div>
                          </div>
                        )))
                    }
                  </div>
                ))}
              </>
            }
          </div>
        ) : (
          <div>
            <p>Loading...</p>
          </div>
        )}
      </div>
    </DashboardLayout >
  )
}

export default ParadoxPostId