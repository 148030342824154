import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const listPlaylists = async (user, page, limit) => {

  try {
    const { data } = await axios.get(`${API_BASE_URL}/playlists/list?page=${page}&limit=${limit}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchPlaylist = async (user, playlist_id) => {

  try {
    const { data } = await axios.get(`${API_BASE_URL}/playlists/${playlist_id}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}


export const updatePlaylist = async (user, id, playlist) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}/playlists/${id || ''}`, playlist,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createPlaylist = async (user, playlist) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/playlists`, playlist,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}