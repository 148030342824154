import "./paradox.css"
import AuthContext from "contexte/AuthContext"
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useContext } from "react";
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { listParadoxProducts, updateParadoxProduct } from "services/paradoxServices";


const Paradox = () => {

    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();


    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [newProduct, setNewProduct] = useState({});
    const [isNewProductOpen, setIsNewProductOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [limit] = useState(10);

    const handleCreateProduct = async (e) => {
        e.preventDefault();
        try {
            await updateParadoxProduct(user, null, newProduct);
            fetchData();
        } catch (err) {
            console.error(err);
            setError(err)
        }
    }

    const fetchData = async () => {

        try {
            const response = await listParadoxProducts(user, page, limit);
            setProducts(response);
            setLoading(false);

        } catch (e) {
            console.error(e);
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [page, limit])

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    }

    const handleInputChange = (e) => {
        setNewProduct({
            ...newProduct,
            [e.target.name]: e.target.value
        })
    }

    return (
        <DashboardLayout page={t('pages_name.structured_products')}>
            <div>
                <div className="ps-header">
                    <div className="ps-header-textbox">
                        <h1 className="no-margin bold ps-title">Nos produits</h1>
                        <p className="no-margin ">Créez et modifiez les produits structurés Goliaths en quelques clics</p>
                    </div>
                    <div className="">
                        <button className="ps-create-new-button" onClick={() => navigate(`/paradox/new`)}>Nouveau produit</button>
                        {
                            isNewProductOpen && (
                                <div className="new_product">
                                    <form onSubmit={handleCreateProduct} className="new_product_form">
                                        <input className="psform-input-text" type="text" name="name" onChange={handleInputChange} />
                                        <button className="ps-create-new-button" type="submit">Créer</button>
                                    </form>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div>
                    {
                        loading ? (
                            <div className='pageLoaderBox'>
                                <PageLoader />
                            </div>
                        ) : (
                            <div>
                                {
                                    !error ? (
                                        <div className="ps-list-box">
                                            {
                                                products.map(item => {
                                                    return (
                                                        <div key={item._id} className="ps-list-item">
                                                            <div className="ps-list-item-name">
                                                                <p className="no-margin bold">{item.name}</p>
                                                            </div>
                                                            {/* <div className="ps-list-item-date">
                                                                <p className="no-margin">{formatDate(item.createdAt)}</p>
                                                            </div> */}
                                                            <div className="ps-list-item-buttons">
                                                                <a href={`/paradox/${item._id}/infos`}><button className="ps-list-item-edit">Modifier</button></a>
                                                                <button className="ps-list-item-delete">Supprimer</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='tableau_pagination'>
                                                {
                                                    page !== 0 && (
                                                        <div className='tableau_nextPage'>
                                                            <button onClick={() => setPage(prev => prev  - 1)} className='button_nextPage'>Page Prédécente</button>
                                                        </div>
                                                    )
                                                }
                                                <div className='actualPage'>{page}</div>
                                                {
                                                    products.length !== 0 && products.length >= limit && (
                                                        <div className='tableau_nextPage'>
                                                            <button onClick={() => setPage(prev => prev + 1)} className='button_nextPage'>Page suivante</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='pageLoaderBox'>
                                            <p>Erreur lors du chargement des données</p>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>

        </DashboardLayout>
    )
}

export default Paradox