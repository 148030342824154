import axios from "axios";
import { convertToUtcISOString } from "utils/date";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchAgbkEconomicProfileReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/economic-profile-monitoring/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadAgbkEconomicProfileReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/economic-profile-monitoring/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAgbkBestExecutionReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/trades/best-execution/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadAgbkBestExecutionReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/trades/best-execution/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const fetchAgbkGovernanceReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/governance/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadAgbkGovernanceReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/governance/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAgbkTransactionsMonitoringReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/transactions-monitoring/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadAgbkTransactionsMonitoringReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/transactions-monitoring/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAgbkCumulativeExpensesReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/cumulative-expenses/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadAgbkCumulativeExpensesReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/compliance/user/cumulative-expenses/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAgbkFundsMovementsReport = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/funds/movements/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadAgbkFundsMovementsReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/funds/movements/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAgbkSumsubReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/sumsub/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadAgbkSumsubReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/ago/reports/sumsub/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}