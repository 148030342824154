import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import { useTranslation } from 'react-i18next'
import "./paradoxLayout.css"
import { useEffect, useState } from "react";


const ParadoxLayout = ({ children }) => {

  const { t } = useTranslation();
  const [selectedTabs, setSelectedTabs] = useState();
  const [productId, setProductId] = useState();

  useEffect(() => {
    setSelectedTabs(window.location.pathname.split('/')[3]);
    setProductId(window.location.pathname.split('/')[2]);
  }, [])

  return (
    <DashboardLayout page={t('pages_name.structured_products')}>
      <div className="pslayout-box">
        <div className="pslayout-header">
          <a href={`/paradox/${productId}/infos`} className="pslayout-header-link"><div className={`${selectedTabs === "infos" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Informations</div></a>
          <a href={`/paradox/${productId}/stocks`} className="pslayout-header-link"><div className={`${selectedTabs === "stocks" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Stocks</div></a>
          <a href={`/paradox/${productId}/playlists`} className="pslayout-header-link"><div className={`${selectedTabs === "playlists" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Playlists</div></a>
          <a href={`/paradox/${productId}/etfs`} className="pslayout-header-link"><div className={`${selectedTabs === "etfs" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>ETFs</div></a>
          <a href={`/paradox/${productId}/structured-product`} className="pslayout-header-link"><div className={`${selectedTabs === "structured-product" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Produit structuré</div></a>
        </div>
        <div >
          {children}
        </div>
      </div>
    </ DashboardLayout>
  )
}

export default ParadoxLayout