import React, { useState, useEffect, useContext } from 'react';
import '../KPI.css';
import AuthContext from 'contexte/AuthContext';
import DateSelector from 'components/Filters/DateSelector/DateSelector';
import { Box } from '../KpiBox/KpiBox';
import { fetchKpiDataForDates } from 'services/kpiServices';
import { MARGIN_RELEASE_DATE, RELEASE_DATE } from 'utils/date';

const KpiMargin = () => {
  const { user } = useContext(AuthContext);
  const [kpiData, setKpiData] = useState({
    selectedData: {
      borrowed_amount_instant: 0,
      borrowed_amount: 0,
      borrowed_amount_fees: 0,
      gains: 0,
      open_positions: 0,
      avg_trade_per_client: 0,
      unique_clients: 0,
      clients_profit_loss: 0,
      volume: 0,
      trades_count: 0,
      new_clients: 0
    },
    comparedData: {
      borrowed_amount_instant: 0,
      borrowed_amount: 0,
      borrowed_amount_fees: 0,
      gains: 0,
      open_positions: 0,
      avg_trade_per_client: 0,
      unique_clients: 0,
      clients_profit_loss: 0,
      volume: 0,
      trades_count: 0,
      new_clients: 0
    }
  });
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(MARGIN_RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchKpiDataForDates(user, 'margin', selectedDate.date1, selectedDate.date2);
      setKpiData(data);
    };
    fetchData();
  }, [user, selectedDate]);

  return (

    <div>
      <div className='kpi_date'>
        <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} withComparaison={true} />
      </div>
      <div className='kpi_container'>
        <Box
          title="En cours d'emprunt"
          data={kpiData.selectedData.borrowed_amount_instant}
          unit='€'
        />
        <Box
          title='Argent emprunté'
          data={kpiData.selectedData.borrowed_amount}
          comparedData={kpiData.comparedData.borrowed_amount}
          unit='€'
        />
        <Box
          title='CA argent emprunté'
          data={kpiData.selectedData.borrowed_amount_fees}
          unit='€'
          comparedData={kpiData.comparedData.borrowed_amount_fees}
        />
        <Box
          title='Volume'
          data={kpiData.selectedData.volume}
          unit='€'
          comparedData={kpiData.comparedData.volume}
          link='/finances/margin-transactions'
        />
        <Box
          title='Gains'
          data={kpiData.selectedData.gains}
          comparedData={kpiData.comparedData.gains}
          unit='€'
        />
        <Box
          title='Positions ouvertes'
          data={kpiData.selectedData.open_positions}
          comparedData={kpiData.comparedData.open_positions}
        />
        <Box
          title='Investisseurs unique'
          data={kpiData.selectedData.unique_clients}
          comparedData={kpiData.comparedData.unique_clients}
        />
        <Box
          title='Clients PL'
          data={kpiData.selectedData.clients_profit_loss}
          comparedData={kpiData.comparedData.clients_profit_loss}
          unit='€'
        />
        <Box
          title='Trades/Client'
          data={kpiData.selectedData.avg_trade_per_client}
          comparedData={kpiData.comparedData.avg_trade_per_client}
        />
        <Box
          title='Nombre de Trades'
          data={kpiData.selectedData.trades_count}
          comparedData={kpiData.comparedData.trades_count}
        />
        <Box
          title='Nouveaux utilisateurs'
          data={kpiData.selectedData.new_clients}
          comparedData={kpiData.comparedData.new_clients}
        />
      </div>
    </div>
  )
}


export default KpiMargin