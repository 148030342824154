import { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import './postList.css'
import { fetchPosts } from 'services/postServices';
import AuthContext from 'contexte/AuthContext';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { formatDate, RELEASE_DATE } from 'utils/date';

const PostList = () => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext)

    const [loading, setLoading] = useState(true)
    const [posts, setPosts] = useState([])
    const [fetchLimit, setFetchLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    const [typeFilter, setTypeFilter] = useState("all")
    const [search, setSearch] = useState("")

    const fetchData = async () => {

        try {
            const getPostsData = await fetchPosts(user, page, fetchLimit, selectedDate.date1.startDate, selectedDate.date1.endDate, typeFilter, search)
            setPosts(getPostsData)
            setLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [page, fetchLimit, selectedDate, search, typeFilter])

    const transformDataToTbody = (data) => {
        return data?.map((item) => {
            return {
                data: [
                    <div className='posts_table_user'>
                        <div className='posts_userInformation_profil_img' style={{ backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/usr/pic/${item.user_uuid})` }} />
                        <p className='posts_p'>{item.firstName} {item.lastName}</p>
                    </div>,
                    formatDate({ date: item.updatedAt, time: true }),
                    item.uuid,
                    item.desc ? item.desc.slice(0, 50) + '...' : item.desc,
                ],
                utils: {
                    id: item.uuid
                }
            }
        })
    }

    const resetFilter = () => {
        setFetchLimit(10)
    }

    return (
        <DashboardLayout page={t("pages_name.posts")}>
            <div>
                <h1>{t("pages.posts.title")}</h1>
                {!loading ? (
                    <div>
                        <FilterComponent
                            limit={true} date={true} gift={false} withSearch={true} posts={true}
                            search={search} setSearch={setSearch}
                            typeFilter={typeFilter} setTypeFilter={setTypeFilter}
                            fetchLimit={fetchLimit} setFetchLimit={setFetchLimit} resetFilter={resetFilter}
                            selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                        />
                        <Tableau
                            tbody={transformDataToTbody(posts)}
                            thead={[
                                t("pages.posts.user"),
                                t("pages.posts.date"),
                                t("pages.posts.uuid"),
                                t("pages.posts.content"),
                            ]}
                            limit={fetchLimit}
                            page={page}
                            setPage={setPage}
                            pagination={true}
                            hadLink={true}
                            linkPath={'/posts/'}

                        />
                    </div>
                ) : (
                    <div>
                        <p>Loading...</p>
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default PostList