// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.posts_table_user{
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px;
    align-items: center;
}

.posts_userInformation_profil_img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.posts_table_user p{
    padding: 0;
}

.posts_p{
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/posts/PostList/postList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,sBAAsB;AAC1B;AACA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb","sourcesContent":[".posts_table_user{\n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    gap: 10px;\n    align-items: center;\n}\n\n.posts_userInformation_profil_img{\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-position: center;\n    background-size: cover;\n}\n.posts_table_user p{\n    padding: 0;\n}\n\n.posts_p{\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
