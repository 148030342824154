import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import './deletedUsers.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import PageLoader from 'components/pageLoader/PageLoader';
import { fetchDeletedUsers } from 'services/userServices';
import TableUser from 'components/TableUser/TableUser';
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import Colors from 'assets/colors';
import Badge from 'components/Badge/Badge';

const DeletedUsers = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [fetchLimit, setFetchLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [alpacaFilter, setAlpacaFilter] = useState('ALL')
  // const [selectedDate, setSelectedDate] = useState({
  //   date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
  //   date2: { startDate: null, endDate: null },
  // })


  const fetchData = async () => {
    try {
      const data = await fetchDeletedUsers({
        user,
        page,
        limit: fetchLimit,
        alpaca: alpacaFilter
      })
      setUsers(data)
    } catch (error) {
      launchError(t('notifications.users.users_deleted.error'))
      setUsers([])
    } finally {
      setLoading(false)
    }
 
  }

  useEffect(() => {
      fetchData()
  }, [page, fetchLimit, alpacaFilter])

  const transformData = (data) => {
    if (data.length > 0) {
      return data.map((item, index) => {
        return {
          data: [
            <TableUser
              fullName={item.name}
              picture={item.uuid}
              key={item.uuid}
            />,
            item.age,
            item.created_at,
            item.deleted_at,
            item.duration,
            <Badge color={Colors.badge[item.alpaca_status]}
              text={item.alpaca_status} />,
            item.trades
          ],
          utils: {
            id: item.uuid
          }
        }
      })
    } else {
      return []
    }
  }

  const handleNextPage = () => {
    setPage((prevState) => prevState + 1)
  }

  const handleFetchLimit = (limit) => {
    setFetchLimit(limit);
    setPage(0);
  }


  return (
    <DashboardLayout page={t("pages_name.deleted_users")}>
      <Notification />
      <div>
        <div className='deleted_users_header_box'>
          <h1>{t("pages.deleted_users.title")}</h1>
        </div>
        {!loading ? (
          <div>
            <FilterComponent
              limit={true} 
              fetchLimit={fetchLimit} setFetchLimit={handleFetchLimit}
              usersFilter={true}
              useAlpaca={true} useSumsub={false} 
              withSearch={false} useSearchFilter={false}
              setAlpacaFilter={setAlpacaFilter}
            />
            <Tableau
              thead={["Utilisateur", "Age", "Date Inscription", "Date de désinscription", "Durée (jours)", "KYC", "Trades"]}
              tbody={transformData(users)}
              limit={fetchLimit}
              pagination={true}
              page={page}
              setPage={setPage}
              handleNextPage={handleNextPage}
              hadLink={true}
              linkPath='/user/'
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default DeletedUsers
