import "../paradoxBuilder.css"
import "./paradoxInfos.css"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { fetchParadoxProduct, updateParadoxProduct, uploadParadoxProductPicture } from "services/paradoxServices";
import { useParams } from 'react-router-dom'
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";
import { fetchTickers } from "services/tickersServices";

const getInitProduct = () => ({
  name: "",
  performance: {
    percentage: 0,
    duration: 0,
    duration_frequency: "years"
  },
  number_of_users: 0,
  image: "",
  statistics: {
    average_holding_period: {
      duration: 0,
      duration_frequency: "years"
    },
    top_stock: "",
    average_yield: 0,
    total_investment: 0
  }

})

const ParadoxInfos = (props) => {

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();


  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState(getInitProduct());
  const [picture, setPicture] = useState(null);

  const [tickers, setTickers] = useState({})
  const [search, setSearch] = useState('')
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearchTicker(search)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setPicture(reader.result)
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProduct = await updateParadoxProduct(user, product._id, product);
      if (picture) {
        await uploadParadoxProductPicture({ user, id: updatedProduct._id, picture })
      }
      setProduct(updatedProduct)
      navigate(`/paradox/${updatedProduct._id}/stocks`)
    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {

    try {
      if (id) {
        const response = await fetchParadoxProduct(user, id);
        setProduct(response)
      }
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e, fieldName) => {
    let name = fieldName || e.target.name
    let value;
    switch (e?.target?.type) {
      case 'number':
        value = Number(e.target.value);
        break;
      case 'text':
      case 'select-one':
        value = e.target.value;
        break;
      default:
        value = e;
        setSearch('')
        break;
    }
    const newProduct = { ...product };
    if (name.includes('.')) {
      const splits = name.split('.');
      const firstLevel = { ...product[splits[0]] };

      let secondLevel = value;
      if (splits.length === 3) {
        secondLevel = { ...firstLevel[splits[1]] };
        secondLevel[splits[2]] = value;
      }
      firstLevel[splits[1]] = secondLevel;

      newProduct[splits[0]] = firstLevel;
    } else {
      newProduct[name] = value
    }
    setProduct(newProduct)
  }

  useEffect(() => {
    const regex = new RegExp(/[0-9]{1,2}-[0-9]{1,2}/, 'g')
    setIsValid(regex.test(product?.performance?.percentage))
  }, [product?.performance?.percentage])

  const handleSearchTicker = async (search) => {
    try {
      if (search.length) {
        const response = await fetchTickers({ user, page: 0, limit: 10, search, leverage: false })
        setTickers(response)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="psform-input-box">
                <span className="psform-input-label">Nom du produit :</span>
                <input className="psform-input-text" type="text" name="name" value={product.name} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Image du produit :</span>
                <input
                  className="psform-input-text"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {
                (imagePreview || product.image) && (
                  <div className="psform-preview-box">
                    <img className="psform-preview-img" src={imagePreview || product.image} alt="Aperçu du produit" />
                  </div>
                )
              }
              <h4>Performance</h4>
              <div className="psform-input-box">
                <span className="psform-input-label">Rendement :</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="text" name="performance.percentage" value={product.performance?.percentage} onChange={handleInputChange} />
                  <span>%</span>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Durée :</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="performance.duration" value={product.performance?.duration} onChange={handleInputChange} />
                  <select value={product.performance?.duration_frequency} className="custom-select" name="performance.duration_frequency" onChange={handleInputChange}>
                    <option value={'years'} type='text'>Année</option>
                    <option value={'months'} type='text'>Mois</option>
                    <option value={'weeks'}>Semaine</option>
                    <option value={'days'}>Jour</option>
                  </select>
                </div>
              </div>

              <h4>Statistiques</h4>
              <div className="psform-input-box">
                <span className="psform-input-label">Durée de détention moyenne:</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="statistics.average_holding_period.duration" value={product.statistics?.average_holding_period?.duration} onChange={handleInputChange} />
                  <select value={product.statistics?.average_holding_period?.duration_frequency} name='statistics.average_holding_period.duration_frequency' className="custom-select" onChange={handleInputChange}>
                    <option value={'years'}>Année</option>
                    <option value={'months'}>Mois</option>
                    <option value={'weeks'}>Semaine</option>
                    <option value={'days'}>Jour</option>
                  </select>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Rendement moyen:</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="statistics.average_yield" value={product.statistics?.average_yield} onChange={handleInputChange} />
                  <span>%</span>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Investissement total:</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="statistics.total_investment" value={product.statistics?.total_investment} onChange={handleInputChange} />
                  <span>€</span>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Top stock :</span>
                <div className='paradox_infos_search_ticker_container'>
                  {(!product.statistics?.top_stock?.id) ? (
                    <input
                      type="text"
                      placeholder={'Rechercher un ticker'}
                      className='paradox_infos_ticker_search_input'
                      onChange={(e) => setSearch(e.target.value)}
                    />

                  ) : (
                    <div className={"search_ticker_selected paradox_infos_relative"}>
                      <span>
                        <div className='paradox_infos_search_ticker_item_select_ticker'>
                          <img className='paradox_infos_search_ticker_picture' src={`https://cdn.goliaths.io/stocks/${product.statistics?.top_stock.ticker}.png`} />
                          {product.statistics?.top_stock?.ticker} | {product.statistics?.top_stock?.name}
                        </div>
                      </span>
                      <div onClick={() => handleInputChange(null, "statistics.top_stock")} className='delete_item_select'>x</div>
                    </div>
                  )}
                  {(!product.statistics?.top_stock?.id) && tickers.length > 0 && search.length > 0 && (
                    <ul className='paradox_infos_search_ticker_list_box'>
                      {tickers.map((ticker, index) => (
                        <li key={index} onClick={() => handleInputChange(ticker, "statistics.top_stock")} className="search_ticker_not_selected">
                          <div className='paradox_infos_search_ticker_item_select_ticker'>
                            <img className='paradox_infos_search_ticker_picture' src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} />
                            {ticker.ticker} | {ticker.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Nombre d'utilisateurs :</span>
                <input className="psform-input-text" type="text" name="number_of_users" value={product.number_of_users} onChange={handleInputChange} />
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit" disabled={!isValid}>Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout >
  )
}

export default ParadoxInfos