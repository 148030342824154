import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import './category.css'
import AuthContext from 'contexte/AuthContext';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { addTickerToCategory, fetchCategoryDetail, fetchCategoryTickers, removeTickerFromCategory } from 'services/categoriesServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import SearchUser from 'components/SearchUser/SearchUser';
import SearchTicker from 'components/SearchTicker/SearchTicker';
import { fetchTickers } from 'services/tickersServices';
import Notification from 'components/notifications/Notification';
import { launchError, launchSuccess } from 'components/notifications';
import TickerImage from 'components/DataComponents/TickerImage/TickerImage';

const Category = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)
  const { id } = useParams();

  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState({})
  const [tickers, setTickers] = useState([])
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)

  const [selectedTicker, setSelectedTicker] = useState({})
  const [searchTickers, setSearchTickers] = useState([])

  const fetchData = async () => {

    try {
      const detail = await fetchCategoryDetail(user, id)
      setCategory(detail)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }

  }

  const getTickers = async () => {
    try {
      const data = await fetchCategoryTickers(user, category.name_en, search, page, limit)
      setTickers(data)
      console.log('tickers have been updated')
    } catch (error) {
      console.error(error)
    }
  }

  const searchTickerFromAllCategory = async (search) => {
    console.log('searchTickerFromAllCategory::search', search)
    if (search.length > 0) {
      try {
        const data = await fetchTickers({ user, page: 0, limit: 10, search })
        setSearchTickers(data)
      } catch (e) {
        console.error(e)
      }
    } else {
      setSearchTickers([])
    }
  }

  const listTickerInCategory = async () => {
    if (selectedTicker.id) {
      try {
        await addTickerToCategory(user, selectedTicker.id, category.name_en);
        setSelectedTicker({});
        await getTickers()
        launchSuccess(t('notifications.actifs.category.addTicker.success'))
      } catch (error) {
        launchError(t('notifications.actifs.category.addTicker.error'))
      }
    }
  }

  const delistTickerFromCategory = async (tickerId) => {
    try {
      await removeTickerFromCategory(user, tickerId, category.name_en);
      await getTickers()
      launchSuccess(t('notifications.actifs.category.removeTicker.success'))
    } catch (error) {
      launchError(t('notifications.actifs.category.removeTicker.error'))
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    getTickers()
  }, [category.name_en, page, limit, search])



  const transformDataToTbody = (data) => {
    return data?.map((item) => {
      return {
        data: [
          <TickerImage ticker={item.ticker} bold />,
          item.name,
          <button
            className='category_add_ticker_button'
            onClick={() => delistTickerFromCategory(item.id)}>Délister</button>
        ]
      }
    })
  }

  return (
    <DashboardLayout page={t("pages_name.categorie")}>
      <div>
        <Notification />
        {!loading ? (
          <div>
            <div className='category_header'>
              <img src={`https://fra1.digitaloceanspaces.com/tsscdn/categories/${category.image}`} alt="categorypic" className='user_profile_pic' />
              <div>
                <p className='category_title'>{category.name}</p>
              </div>
            </div>
            {/* <div className='externalDeposit_titleComponent'>
              <h2><img src={`https://fra1.digitaloceanspaces.com/tsscdn/categories/${category.image}`}></img>Détail de la catégorie: {category.name}</h2>
            </div> */}
            <div className='category_add_ticker_box'>
              <SearchTicker
                setSelectedTicker={setSelectedTicker}
                selectedTicker={selectedTicker}
                handleSearch={searchTickerFromAllCategory}
                tickers={searchTickers}
                label='Ajouter un ticker à la catégorie'
              />
              <button
                className='category_add_ticker_button'
                disabled={!selectedTicker?.ticker}
                onClick={() => listTickerInCategory()
                }>Ajouter</button>
            </div>
            <div className='category_filter_component_box'>
              <FilterComponent
                limit={true}
                fetchLimit={limit}
                setFetchLimit={setLimit}
                withSearch={true}
                search={search}
                setSearch={setSearch}
              />
            </div>
            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={["Ticker", "Name", '']}
                  tbody={transformDataToTbody(tickers)}
                  pagination={true}
                  page={page}
                  setPage={setPage}
                  limit={limit}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>Loading..</div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Category