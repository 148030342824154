import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { fetchPlaylists } from "services/tickersServices"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../paradoxBuilder.css"
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";
import { fetchParadoxProduct, updateParadoxProduct } from "services/paradoxServices";
import { useParams } from "react-router-dom";

const ParadoxPlaylists = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [searchedPlaylists, setSearchedPlaylists] = useState([]);
  const [search, setSearch] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateParadoxProduct(user, id, product);
      navigate(`/paradox/${id}/etfs`)

    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchParadoxProduct(user, id);
      setProduct({
        ...response
      })
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const handleSearchPlaylist = async (e) => {
    try {
      setSearch(e.target.value)
      const response = await fetchPlaylists(user, 0, 10, e.target.value)
      setSearchedPlaylists(response)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectPlaylist = (selectedPlaylist) => {
    setSearch('')
    const currentPlaylists = product.playlists ? [...product.playlists] : [];
    if (!currentPlaylists.some(playlist => playlist._id === selectedPlaylist._id)) {
      currentPlaylists.push(selectedPlaylist);
    }
    setProduct({
      ...product,
      playlists: currentPlaylists
    });
  }


  const handleRemovePlaylist = (playlist_id) => {
    const updatedPlaylists = product.playlists.filter(playlist => playlist._id !== playlist_id);
    setProduct({
      ...product,
      playlists: updatedPlaylists
    });
  }



  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div>
                <div className="paradox_playlists_search">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Produits associés :</span>
                    <input className="psform-input-text" type="text" name="products" placeholder="Rechercher une playlist" value={search} onChange={handleSearchPlaylist} />
                  </div >
                  {
                    searchedPlaylists.length > 0 && search.length > 0 && (
                      <div className="paradox_playlists_search_results">
                        {searchedPlaylists.map((playlist, index) => (
                          <div key={index} className="paradox_playlists_search_results_item pointer" onClick={() => handleSelectPlaylist(playlist)}>
                            <p>{playlist.name}</p>
                            <p>{playlist.author_firstName} | {playlist.author_lastName}</p>
                          </div >
                        ))}
                      </div>
                    )
                  }
                </div>
                <div className="paradox_playlists_box">
                  {
                    product?.playlists?.map((playlist, index) => (

                      <div className="paradox_playlists_item_box" style={{ backgroundColor: `${playlist.color}AF` }} key={playlist._id}>
                        <p className="paradox_playlists_item_delete_button" onClick={() => handleRemovePlaylist(playlist._id)}>x</p>
                        <p className="paradox_playlists_item_title">{playlist.name}</p>
                        {
                          playlist.tickers.map((ticker, index) => (
                            <div key={index} className="psform-ticker-selected-box pointer" style={{ backgroundColor: `${playlist.color}AA` }}>
                              <div className="ticker-desc">
                                <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} alt="" />
                                <span className="psform-input-label">{ticker.ticker}</span>
                              </div>
                            </div >
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout>
  )
}

export default ParadoxPlaylists