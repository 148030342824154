import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const listParadoxProducts = async (user, page, limit) => {

  try {
      const { data } = await axios.get(`${API_BASE_URL}/paradox/list?page=${page}&limit=${limit}`,
          buildHeaders(user)
      );
      return data;
  } catch (error) {
      throw error
  }
}

export const fetchParadoxProduct = async (user, product_id) => {

  try {
      const { data } = await axios.get(`${API_BASE_URL}/paradox/${product_id}`,
          buildHeaders(user)
      );
      return data;
  } catch (error) {
      throw error
  }
}

export const uploadParadoxProductPicture = async ({ user, id, picture }) => {
    try {
      const { data } = await axios.post(`${API_BASE_URL}/paradox/${id}/picture`, { picture },
        buildHeaders(user)
      )
      return data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  export const updateParadoxProduct = async (user, id, product) => {
    try {
      const { data } = await axios.put(`${API_BASE_URL}/paradox/${id || ''}`, product,
        buildHeaders(user)
      )
      return data
    } catch (err) {
      console.error(err)
      throw err
    }
  }