import "./notificationsList.css"
import AuthContext from "contexte/AuthContext"
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useContext } from "react";
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { deleteNotification, listNotifications } from "services/notificationsServices";
import { formatDate } from "utils/date";
import { launchError, launchSuccess } from "components/notifications";
import Notification from "components/notifications/Notification";


const NotificationsList = () => {

    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();


    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [limit] = useState(10);

    const fetchData = async () => {

        try {
            const response = await listNotifications(user, page, limit);
            setNotifications(response);
            setLoading(false);

        } catch (e) {
            console.error(e);
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [page, limit])


    const handleDelete = async (index) => {
        try {
            await deleteNotification(user, notifications[index]._id)
            // notif
            launchSuccess(t('notifications.notifications.delete.success'))
            const updatedNotifications = [...notifications];
            updatedNotifications.splice(index, 1);
            setNotifications(updatedNotifications);
        } catch (error) {
            launchError(t('notifications.notifications.delete.error'))
        }
    }

    return (
        <DashboardLayout page={t('pages_name.notifications')}>
            <div>
                <Notification />
                <div className="notifications_header">
                    <div className="notifications_header_textbox">
                        <h1 className="no_margin bold notifications_title">Notifications</h1>
                        <p className="no_margin ">Créez et modifiez les notifications en quelques clics</p>
                    </div>
                    <div className="">
                        <button className="notifications_create_new_button" onClick={() => navigate(`/notifications/new`)}>Nouvelle notification</button>
                    </div>
                </div>
                <div>
                    {
                        loading ? (
                            <div className='pageLoaderBox'>
                                <PageLoader />
                            </div>
                        ) : (
                            <div>
                                {
                                    !error ? (
                                        <div className="notifications_list_box">
                                            {
                                                notifications.map((item, index) => {
                                                    return (
                                                        <div key={item._id} className="notifications_list_item">
                                                            <div className="playlits_list_item_title">
                                                                <p className="no_margin bold">{item.title}</p>
                                                            </div>
                                                            <div className="playlits_list_item_type">
                                                                <p className="no_margin bold">{item.type}</p>
                                                            </div>
                                                            <div className="playlits_list_item_date">
                                                                <p className="no_margin bold">{formatDate({ date: item.date, time: true })}</p>
                                                            </div>
                                                            <div className="notifications_list_item_buttons">
                                                                <a href={`/notifications/${item._id}`}><button className="notifications_list_item_edit">Modifier</button></a>
                                                                <button className="notifications_list_item_delete" onClick={() => handleDelete(index)}>Supprimer</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='tableau_pagination'>
                                                {
                                                    page !== 0 && (
                                                        <div className='tableau_nextPage'>
                                                            <button onClick={() => setPage(prev => prev - 1)} className='button_nextPage'>Page Prédécente</button>
                                                        </div>
                                                    )
                                                }
                                                <div className='actualPage'>{page}</div>
                                                {
                                                    notifications.length !== 0 && notifications.length >= limit && (
                                                        <div className='tableau_nextPage'>
                                                            <button onClick={() => setPage(prev => prev + 1)} className='button_nextPage'>Page suivante</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='pageLoaderBox'>
                                            <p>Erreur lors du chargement des données</p>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>

        </DashboardLayout>
    )
}

export default NotificationsList