import "../paradoxBuilder.css"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { fetchParadoxProduct, updateParadoxProduct } from "services/paradoxServices";
import { useParams } from 'react-router-dom'
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";

const getInitProduct = () => ({
    name: "",
    performance: {
      percentage: 0,
      duration: 0,
      duration_frequency: ""
    },
    number_of_users: 0,
    image: "",
    statistics: {
      average_holding_period: {
        duration: 0,
        duration_frequency: ""
      },
      top_stock: "",
      average_yield: 0,
      total_investment: 0
    }
 
})

const ParadoxInfos = (props) => {

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();


  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState(getInitProduct());
  const [picture, setPicture] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setPicture(reader.result)
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProduct = await updateParadoxProduct(user, product._id, product);
      // if (picture) {
      //   await uploadParadoxProductPicture({ user, id: updatedProduct._id, type: 'main', picture })
      // }
      setProduct(updatedProduct)
      navigate(`/paradox/${updatedProduct._id}/stocks`)

    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {

    try {
      if (id) {
        const response = await fetchParadoxProduct(user, id);
        setProduct(response)
      }
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value
    const newProduct = { ...product };
    if (e.target.name.includes('.')) {
      const splits = e.target.name.split('.');
      const firstLevel = { ...product[splits[0]] };

      let secondLevel = value;
      if (splits.length === 3) {
        secondLevel = { ...firstLevel[splits[1]] };
        secondLevel[splits[2]] = value;
      }
      firstLevel[splits[1]] = secondLevel;

      newProduct[splits[0]] = firstLevel;
    } else {
      newProduct[e.target.name] = value
    }
    setProduct(newProduct)
  }

  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              {/* <div className="psform-input-box">
                <span className="psform-input-label">ISIN :</span>
                <input className="psform-input-text" type="text" name="isin" value={product.isin} onChange={handleInputChange} />
              </div > */}
              <div className="psform-input-box">
                <span className="psform-input-label">Nom du produit :</span>
                <input className="psform-input-text" type="text" name="name" value={product.name} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Image du produit :</span>
                <input
                  className="psform-input-text"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {
                (imagePreview || product.image) && (
                  <div className="psform-preview-box">
                    <img className="psform-preview-img" src={imagePreview || product.image} alt="Aperçu du produit" />
                  </div>
                )
              }
              <h4>Performance</h4>
              <div className="psform-input-box">
                <span className="psform-input-label">Rendement :</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="performance.percentage" value={product.performance?.percentage} onChange={handleInputChange} />
                  <span>%</span>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Durée :</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="performance.duration" value={product.performance?.duration} onChange={handleInputChange} />
                  <select value={product.performance?.duration_frequency} className="custom-select" name="performance.duration_frequency" onChange={handleInputChange}>
                    <option value={'years'}>Année</option>
                    <option value={'months'}>Mois</option>
                    <option value={'weeks'}>Semaine</option>
                    <option value={'days'}>Jour</option>
                  </select>
                </div>
              </div>

              <h4>Statistiques</h4>
              <div className="psform-input-box">
                <span className="psform-input-label">Durée de détention moyenne:</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="statistics.average_holding_period.duration" value={product.statistics?.average_holding_period?.duration} onChange={handleInputChange} />
                  <select value={product.statistics?.average_holding_period?.duration_frequency} name='statistics.average_holding_period.duration_frequency' className="custom-select" onChange={handleInputChange}>
                    <option value={'years'}>Année</option>
                    <option value={'months'}>Mois</option>
                    <option value={'weeks'}>Semaine</option>
                    <option value={'days'}>Jour</option>
                  </select>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Rendement moyen:</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="statistics.average_yield" value={product.statistics?.average_yield} onChange={handleInputChange} />
                  <span>%</span>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Investissement total:</span>
                <div className="psform-input-row">
                  <input className="psform-input-text-multi" type="number" name="statistics.total_investment" value={product.statistics?.total_investment} onChange={handleInputChange} />
                  <span>€</span>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Top stock :</span>
                <input className="psform-input-text" type="text" name="statistics.top_stock" value={product.statistics?.top_stock} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Nombre d'utilisateurs :</span>
                <input className="psform-input-text" type="text" name="number_of_users" value={product.number_of_users} onChange={handleInputChange} />
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout >
  )
}

export default ParadoxInfos