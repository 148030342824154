import axios from "axios";

const buildHeaders = (user) => ({
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user}`
    }
})

const BASE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION;

export const fetchUserById = async (user, id) => {

    try {
        const response = await axios.get(BASE_URL + "/users/clients/" + id,
            buildHeaders(user));
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchFeaturesById = async (user, id) => {

    try {
        const response = await axios.get(BASE_URL + "/users/clients/details/features/" + id,
            buildHeaders(user));
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updateUserFeatures = async (user, id, data) => {

    try {
        const response = await axios.post(BASE_URL + "/users/clients/details/features/" + id,
            {
                features: data
            },
            buildHeaders(user));
        return response.data;
    } catch (error) {
        console.error(error);
    }

}



export const fetchTransactions = async (user, id, page, fetchLimit) => {

    try {
        const response = await axios.get(BASE_URL + "/users/clients/" + id + "/trades?page=" + page + "&limit=" + fetchLimit,
            buildHeaders(user));
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchCashLogs = async (user, id, page, fetchLimit) => {

    try {
        const response = await axios.get(BASE_URL + "/users/clients/" + id + "/cash-logs?page=" + page + "&limit=" + fetchLimit,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user}`
                }
            });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchPortfolio = async (user, uuid, page, fetchLimit) => {

    try {
        const { data } = await axios.get(`${BASE_URL}/users/clients/${uuid}/portfolio?page=${page}&limit=${fetchLimit}`,
            buildHeaders(user)
        );
        return data;
    } catch (error) {
        throw error
    }
}

export const fetchStructuredProducts = async (user, uuid, page, fetchLimit) => {

    try {
        const { data } = await axios.get(`${BASE_URL}/users/clients/${uuid}/structured-products?page=${page}&limit=${fetchLimit}`,
            buildHeaders(user)
        );
        return data;
    } catch (error) {
        throw error
    }
}

export const fetchFraudCheck = async (user, uuid) => {
    try {
        const { data } = await axios.get(`${BASE_URL}/users/clients/${uuid}/fraud-check`,
            buildHeaders(user)
        );
        return data;
    } catch (error) {
        throw error
    }
}

export const updateUser = async (user, id, updates) => {

    try {
        const { data } = await axios.post(BASE_URL + "/users/clients/details/update/" + id,
            {
                ...updates,
                user_uuid: id
            },
            buildHeaders(user));
        console.log('data', data)
        return data;
    } catch (error) {
        console.error(error);
        throw error
    }
}

export const uploadUserDocument = async (user, id, file, type) => {
    try {
        const { data } = await axios.post(`${BASE_URL}/users/clients/${id}/upload-document?type=${type}`, { data: file, type },
            buildHeaders(user))
        return data;
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const uploadUserRiskLevel = async (user, id, risk_level) => {
    try {
        const { data } = await axios.post(`${BASE_URL}/users/clients/${id}/risk-level`, { risk_level },
            buildHeaders(user))
        return data;
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const fetchLeverageUserPositions = async (user, uuid, page, fetchLimit) => {

    try {
        const { data } = await axios.get(`${BASE_URL}/leverage/open-positions/user/${uuid}?page=${page}&limit=${fetchLimit}`,
            buildHeaders(user)
        );
        return data;
    } catch (error) {
        throw error
    }
}