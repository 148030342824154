import { useEffect, useState, useContext } from 'react';
import { downloadBestExecutionReport, fetchBestExecutionReports } from 'services/complianceReportsServices';
import AuthContext from "contexte/AuthContext"
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import './bestExecution.css'

const BestExecution = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const data = await fetchBestExecutionReports(user);
        setFiles(data);
      } catch (error) {
        launchError(t('notifications.reports.compliance.list.error'))
      }
    }

    fetchReportList();
  }, [])

  return (
    <DashboardLayout page={t(`pages_name.report_compliance_best_execution`)}>
      <Notification />
      <div className='bestExecution_titleComponent'>
        <h2>{t(`pages.best_execution.title`)}</h2>
        {/* <FilterComponent
          date={true}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          maxDate={yesterday}
        /> */}
      </div>
      <div>
        <ul>
          {files.map(file => (
            <DownloaderLink
              filename={`best_execution_${file}.xlsx`}
              fetchData={downloadBestExecutionReport}
              fetchDataParams={{ user, fileName: file }}
              type={'excel'}
              display={file}
              key={file}
            />
          ))}
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default BestExecution;