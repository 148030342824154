import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const listNotifications = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/notifications`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const fetchNotification = async (user, id) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/notifications/${id}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const createNotification = async (user, notification) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/notifications`,
      notification,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const updateNotification = async (user, id, notification) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}/notifications/${id}`,
      notification,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const deleteNotification = async (user, id) => {
  try {
    const { data } = await axios.delete(`${API_BASE_URL}/notifications/${id}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const notifyUsers = async (user, notification) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/notify`,
      notification,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

