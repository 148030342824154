import {
  Routes,
  Route,
} from 'react-router-dom'

import ProtectedRoutes from './components/ProtectedRoutes';
import Home from './pages/home/Home';
import Profil from './pages/profile/Profil'


//Finances
import Finances from './pages/finances/Finances'
import Comptes from './pages/finances/subpages/Comptes/Comptes'
import Payouts from './pages/finances/subpages/Payouts/Payouts'
import ExternalDeposit from './pages/finances/subpages/External_deposit/ExternalDeposit'
import Iban from './pages/finances/subpages/Ibans/Iban'
import IbanById from './pages/finances/subpages/Ibans/ibanById/IbanById';
import Transactions from './pages/finances/subpages/Transactions/Transactions'
import DepositById from 'pages/finances/subpages/Transactions/WithdrawDeposit/depositById/depositById';
import Orders from './pages/finances/subpages/Orders/Orders'
import Problems from './pages/finances/subpages/Problems/Problems'

//Cadeaux
import Cadeaux from './pages/cadeaux/Cadeaux'

//Actifs
import Actifs from './pages/actifs/Actifs';
import AlpacaRoot from './pages/actifs/subpages/Alpaca_root/AlpacaRoot';
import Categories from './pages/actifs/subpages/categories/Categories';
import Tickers from './pages/actifs/subpages/Tickers/Tickers';
import TickersNotFound from './pages/actifs/subpages/TickersNotFound/TickersNotFound';

//Posts
import Posts from './pages/posts/Posts';
import Subposts from 'pages/posts/Subposts/Subposts';

//Rapports
import UsersReport from './pages/reports/users/UsersReports';
import Trades from './pages/reports/trades/Trades';
import Money from './pages/reports/money/Money';

//Users
import Users from './pages/users/Users';
import UserById from 'pages/users/userById/UserById';
import TradeVolume from 'pages/reports/trades/allComponents/TradeVolume/TradeVolume';
import WaitingTradeVolume from 'pages/reports/trades/allComponents/WaitingTradeVolume/WaitingTradeVolume';
import Gifts from 'pages/cadeaux/subpages/List/Gifts';
import NewGift from 'pages/cadeaux/subpages/NewGift/NewGift';
import ManualPayout from 'pages/finances/subpages/ManualPayout/ManualPayout';
import MostImportantClients from 'pages/reports/users/allComponents/MostImportantClients/MostImportantClients';
import NewClients from 'pages/reports/users/allComponents/NewClients/NewClients';
import AverageVolumePerClient from 'pages/reports/users/allComponents/AverageVolumePerClient/AverageVolumePerClient';
import ReportGifts from 'pages/reports/users/allComponents/ReportGifts/ReportGifts';
import VolumePerStockList from 'pages/reports/trades/allComponents/VolumePerStockList/VolumePerStockList';
import VolumePerStock from 'pages/reports/trades/allComponents/VolumePerStock/VolumePerStock';
import VolumeInTrade from 'pages/reports/trades/allComponents/VolumeInTrade/VolumeInTrade';
import AvailableMoney from 'pages/reports/trades/allComponents/AvailableMoney/AvailableMoney';
import CardFees from 'pages/reports/trades/allComponents/CardFees/CardFees';
import Profits from 'pages/reports/money/allComponents/Profits/Profits';
import ProfitsPerStock from 'pages/reports/money/allComponents/ProfitsPerStock/ProfitsPerStock';
import WithdrawById from 'pages/finances/subpages/Transactions/WithdrawDeposit/withdrawById/WithdrawById';
import CardTradeById from 'pages/finances/subpages/Transactions/CardTransactions/CardTradeById/CardTradeById';
import Analytics from 'pages/users/analytics/Analytics';
import InvestmentPlans from 'pages/finances/subpages/InvestmentPlans/InvestmentPlans';
import Reconciliations from 'pages/actifs/subpages/Reconciliations/Reconciliations';
import Queues from 'pages/queues/Queues';
import StructuredProducts from 'pages/finances/subpages/StructuredProducts/StructuredProducts';
import StructuredProductById from 'pages/finances/subpages/StructuredProducts/StructuredProductById/StructuredProductById';
import Creators from 'pages/users/creators/Creators';
import CreatorsByCategory from 'pages/users/creators/creatorsByCategory/CreatorsByCategory';
import StructuredProductsTrades from 'pages/finances/subpages/StructuredProductsTrades/StructuredProductsTrades';
import ComplianceReports from 'pages/reports/compliance/ComplianceReports';
import Truelayer from 'pages/actifs/subpages/Truelayer/Truelayer';
import UsersToCall from 'pages/users/usersToCall/UsersToCall';
import FundsMovements from 'pages/reports/accounts/FundsMovements/FundsMovements';
import NewTicker from 'pages/actifs/subpages/NewTicker/NewTicker';
import Structured from 'pages/structured/Structured';
import PsInfos from 'pages/structured/builder/infos/Infos';
import PsFinances from 'pages/structured/builder/finances/Finances';
import Forms from 'pages/forms/Forms';
import SubJacent from 'pages/structured/builder/SubJacent/SubJacent';
import PsTexts from 'pages/structured/builder/texts/Texts';
import Dates from 'pages/structured/builder/dates/Dates';
import Recap from 'pages/structured/builder/recap/Recap';
import UsersPositions from 'pages/reports/accounts/UsersPositions/UsersPositions';
import SumsubReports from 'pages/reports/compliance/Sumsub/Sumsub';
import ClosedAccountsReport from 'pages/reports/compliance/ClosedAccounts/ClosedAccounts';
import SumsubRejectedReports from 'pages/reports/compliance/SumsubRejected/SumsubRejected';
import SumsubManuallyAcceptedReports from 'pages/reports/compliance/SumsubManuallyAccepted/SumsubManuallyAccepted';
import SumsubScreeningReports from 'pages/reports/sumsubScreening/SumsubScreeningReports';
import TransactionsReporting from 'pages/reports/transactionsReporting/TransactionsReporting';
import SumsubScreeningFolderList from 'pages/reports/sumsubScreening/folderList/ScreeningFolderList';
import SumsubScreeningReportList from 'pages/reports/sumsubScreening/reportList/ScreeningReportList';
import Category from 'pages/actifs/subpages/categories/Category/Category';
import EconomicProfile from 'pages/reports/compliance/EconomicProfile/EconomicProfile';
import BestExecution from 'pages/reports/compliance/BestExecution/BestExecution';
import DeletedUsers from 'pages/users/deletedUsers/DeletedUsers';
import Features from 'pages/finances/subpages/Features/Features';
import VolumePerCommodityOnTimeframe from 'pages/reports/trades/allComponents/VolumePerCommodityOnTimeframe/VolumePerCommodityOnTimeframe';
import VolumePerCommodity from 'pages/reports/trades/allComponents/VolumePerCommodity/VolumePerCommodity';
import UsersWallet from 'pages/reports/accounts/UsersWallet/UsersWallet';
import AccountsReports from 'pages/reports/accounts/AccountsReports';
import Governance from 'pages/reports/compliance/Governance/Governance';
import TransactionsMonitoring from 'pages/reports/compliance/TransactionsMonitoring/TransactionsMonitoring';
import AgoUsers from 'pages/ago/AgoUsers';
import AgoUser from 'pages/ago/AgoUser/AgoUser';
import AgoDeposits from 'pages/ago/AgoDeposits/AgoDeposits';
import AgoPayouts from 'pages/ago/AgoPayouts/AgoPayouts';
import MarginTransactions from 'pages/finances/subpages/MarginTransactions/MarginTransactions';
import MarginTransactionById from 'pages/finances/subpages/MarginTransactions/MarginTransactionById/MarginTransactionById';
import AgoTransactions from 'pages/ago/AgoTransactions/AgoTransactions';
import AgoTransactionById from 'pages/ago/AgoTransactions/AgoTransactionById/AgoTransactionById';
import CumulativeExpenses from 'pages/reports/compliance/CumulativeExpenses/CumulativeExpenses';
import EtfEdition from 'pages/actifs/subpages/EtfEdition/EtfEdition';
import UserNotify from 'pages/users/userNotify/UserNotify';
import Discount from 'pages/cadeaux/subpages/Discount/Discount';
import Accounts from 'pages/finances/subpages/Accounts/Accounts';
import UsersTraded from 'pages/reports/compliance/UsersTraded/UsersTraded';
import UtilityBills from 'pages/finances/subpages/UtilityBills/UtilityBills';
import UtilityBill from 'pages/finances/subpages/UtilityBills/UtilityBill/UtilityBill';
import FundsMovementsAgbk from 'pages/reports/complianceAgo/FundsMovementsAgbk/FundsMovementsAgbk';
import AddMarginTicker from 'pages/actifs/subpages/AddMarginTicker/AddMarginTicker';
import BestExecutionAgbk from 'pages/reports/complianceAgo/BestExecutionAgbk/BestExecutionAgbk';
import EconomicProfileAgbk from 'pages/reports/complianceAgo/EconomicProfileAgbk/EconomicProfileAgbk';
import GovernanceAgbk from 'pages/reports/complianceAgo/GovernanceAgbk/GovernanceAgbk';
import TransactionsMonitoringAgbk from 'pages/reports/complianceAgo/TransactionsMonitoringAgbk/TransactionsMonitoringAgbk';
import CumulativeExpensesAgbk from 'pages/reports/complianceAgo/CumulativeExpensesAgbk/CumulativeExpensesAgbk';
import ComplianceReportsAgbk from 'pages/reports/complianceAgo/ComplianceReportsAgbk';
import SumsubAgbkReports from 'pages/reports/complianceAgo/SumsubAgbk/SumsubAgbk';
import Paradox from 'pages/paradox/Paradox';
import ParadoxInfos from 'pages/paradox/builder/infos/ParadoxInfos';
import ParadoxStocks from 'pages/paradox/builder/stocks/ParadoxStocks';
import ParadoxPlaylists from 'pages/paradox/builder/playlists/ParadoxPlaylists';
import ParadoxEtfs from 'pages/paradox/builder/etf/ParadoxEtfs';
import ParadoxSp from 'pages/paradox/builder/structured_products/ParadoxSp';


function App() {

  return (
    <div className="App">
      <Routes>
        {/* Utils */}
        <Route exact path='/' element={<ProtectedRoutes role={[1, 2, 3]}><Home /></ProtectedRoutes>} />
        <Route exact path='/profile' element={<ProtectedRoutes role={[1, 2, 3]}><Profil /></ProtectedRoutes>} />

        {/* Finances */}
        <Route exact path='/finances' element={<ProtectedRoutes role={[1, 2, 3]}><Finances /></ProtectedRoutes>} />
        <Route exact path='/finances/accounts' element={<ProtectedRoutes role={[1, 2, 3]}><Comptes /></ProtectedRoutes>} />
        <Route exact path='/finances/accounts-ibanfirst' element={<ProtectedRoutes role={[1, 2, 3]}><Accounts /></ProtectedRoutes>} />
        <Route exact path='/finances/payouts' element={<ProtectedRoutes role={[1, 2, 3]}><Payouts /></ProtectedRoutes>} />
        <Route exact path='/finances/external-deposit' element={<ProtectedRoutes role={[1, 2, 3]}><ExternalDeposit /></ProtectedRoutes>} />
        <Route exact path='/finances/iban' element={<ProtectedRoutes role={[1, 2, 3]}><Iban /></ProtectedRoutes>} />
        <Route exact path='/finances/iban/:id' element={<ProtectedRoutes role={[1, 2, 3]}><IbanById /></ProtectedRoutes>} />
        <Route exact path='/finances/trades' element={<ProtectedRoutes role={[1, 2, 3]}><Transactions /></ProtectedRoutes>} />
        <Route exact path='/finances/trades/:type/:id' element={<ProtectedRoutes role={[1, 2, 3]}><CardTradeById /></ProtectedRoutes>} />
        <Route exact path='/finances/trades/deposit/:id' element={<ProtectedRoutes role={[1, 2, 3]}><DepositById /></ProtectedRoutes>} />
        <Route exact path='/finances/trades/withdraw/:id' element={<ProtectedRoutes role={[1, 2, 3]}><WithdrawById /></ProtectedRoutes>} />
        <Route exact path='/finances/orders' element={<ProtectedRoutes role={[1, 2, 3]}><Orders /></ProtectedRoutes>} />
        <Route exact path='/finances/problems' element={<ProtectedRoutes role={[1, 2, 3]}><Problems /></ProtectedRoutes>} />
        <Route exact path='/finances/manual-payout' element={<ProtectedRoutes role={[1, 2, 3]}><ManualPayout /></ProtectedRoutes>} />
        <Route exact path='/finances/investment-plans' element={<ProtectedRoutes role={[1, 2, 3]}><InvestmentPlans /></ProtectedRoutes>} />
        <Route exact path='/finances/structured-products' element={<ProtectedRoutes role={[1, 2, 3]}><StructuredProducts /></ProtectedRoutes>} />
        {/* <Route exact path='/finances/structured-products/trades' element={<ProtectedRoutes role={[1, 2, 3]}><StructuredProductsTrades /></ProtectedRoutes>} /> */}
        <Route exact path='/finances/structured-product/:product_id/:status' element={<ProtectedRoutes role={[1, 2, 3]}><StructuredProductById /></ProtectedRoutes>} />
        <Route exact path='/finances/features' element={<ProtectedRoutes role={[1, 2, 3]}><Features /></ProtectedRoutes>} />
        <Route exact path='/finances/margin-transactions' element={<ProtectedRoutes role={[1, 2, 3]}><MarginTransactions /></ProtectedRoutes>} />
        <Route exact path='/finances/margin-transaction/:id' element={<ProtectedRoutes role={[1, 2, 3]}><MarginTransactionById /></ProtectedRoutes>} />
        <Route exact path='/finances/utility-bills' element={<ProtectedRoutes role={[1, 2, 3]}><UtilityBills /></ProtectedRoutes>} />
        <Route exact path='/finances/utility-bills/:id' element={<ProtectedRoutes role={[1, 2, 3]}><UtilityBill /></ProtectedRoutes>} />

        {/* Cadeaux */}
        <Route exact path='/gifts' element={<ProtectedRoutes role={[1, 2, 3]}><Cadeaux /></ProtectedRoutes>} />
        <Route exact path='/gifts/list' element={<ProtectedRoutes role={[1, 2, 3]}><Gifts /></ProtectedRoutes>} />
        <Route exact path='/gifts/new' element={<ProtectedRoutes role={[1, 2, 3]}><NewGift /></ProtectedRoutes>} />
        <Route exact path='/gifts/discount' element={<ProtectedRoutes role={[1, 2, 3]}><Discount /></ProtectedRoutes>} />

        {/* Actifs */}
        <Route exact path='/actifs' element={<ProtectedRoutes role={[1, 2, 3]}><Actifs /></ProtectedRoutes>} />
        <Route exact path='/actifs/alpaca' element={<ProtectedRoutes role={[1, 2, 3]}><AlpacaRoot /></ProtectedRoutes>} />
        <Route exact path='/actifs/truelayer' element={<ProtectedRoutes role={[1, 2, 3]}><Truelayer /></ProtectedRoutes>} />
        <Route exact path='/actifs/categories' element={<ProtectedRoutes role={[1, 2, 3]}><Categories /></ProtectedRoutes>} />
        <Route exact path='/actifs/category/:id' element={<ProtectedRoutes role={[1, 2, 3]}><Category /></ProtectedRoutes>} />
        <Route exact path='/actifs/tickers' element={<ProtectedRoutes role={[1, 2, 3]}><Tickers /></ProtectedRoutes>} />
        <Route exact path='/actifs/tickers/not-found' element={<ProtectedRoutes role={[1, 2, 3]}><TickersNotFound /></ProtectedRoutes>} />
        <Route exact path='/actifs/tickers/new' element={<ProtectedRoutes role={[1, 2, 3]}><NewTicker /></ProtectedRoutes>} />
        <Route exact path='/actifs/tickers/add_margin' element={<ProtectedRoutes role={[1, 2, 3]}><AddMarginTicker /></ProtectedRoutes>} />
        <Route exact path='/actifs/etf/edit' element={<ProtectedRoutes role={[1, 2, 3]}><EtfEdition /></ProtectedRoutes>} />
        <Route exact path='/actifs/reconciliations' element={<ProtectedRoutes role={[1, 2, 3]}><Reconciliations /></ProtectedRoutes>} />

        {/* Posts */}
        <Route exact path='/posts' element={<ProtectedRoutes role={[1, 2, 3]}><Posts /></ProtectedRoutes>} />
        <Route exact path='/posts/:id' element={<ProtectedRoutes role={[1, 2, 3]}><Subposts /></ProtectedRoutes>} />

        {/* Posts */}
        <Route exact path='/paradox' element={<ProtectedRoutes role={[1, 2, 3]}><Paradox /></ProtectedRoutes>} />
        <Route exact path='/paradox/new' element={<ProtectedRoutes role={[1, 2, 3]}><ParadoxInfos /></ProtectedRoutes>} />
        <Route exact path='/paradox/:id/infos' element={<ProtectedRoutes role={[1, 2, 3]}><ParadoxInfos /></ProtectedRoutes>} />
        <Route exact path='/paradox/:id/stocks' element={<ProtectedRoutes role={[1, 2, 3]}><ParadoxStocks /></ProtectedRoutes>} />
        <Route exact path='/paradox/:id/playlists' element={<ProtectedRoutes role={[1, 2, 3]}><ParadoxPlaylists /></ProtectedRoutes>} />
        <Route exact path='/paradox/:id/etfs' element={<ProtectedRoutes role={[1, 2, 3]}><ParadoxEtfs /></ProtectedRoutes>} />
        <Route exact path='/paradox/:id/structured-product' element={<ProtectedRoutes role={[1, 2, 3]}><ParadoxSp /></ProtectedRoutes>} />

        {/* Rapports */}
        <Route exact path='/reports/users' element={<ProtectedRoutes role={[1, 2, 3]}><UsersReport /></ProtectedRoutes>} />
        <Route exact path='/reports/trades' element={<ProtectedRoutes role={[1, 2, 3]}><Trades /></ProtectedRoutes>} />
        <Route exact path='/reports/money' element={<ProtectedRoutes role={[1, 2, 3]}><Money /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance' element={<ProtectedRoutes role={[1, 2, 3]}><ComplianceReports /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/sumsub' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubReports /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/sumsub-rejected' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubRejectedReports /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/sumsub-manually-accepted' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubManuallyAcceptedReports /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/closed-accounts' element={<ProtectedRoutes role={[1, 2, 3]}><ClosedAccountsReport /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/economic-profile' element={<ProtectedRoutes role={[1, 2, 3]}><EconomicProfile /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/best-execution' element={<ProtectedRoutes role={[1, 2, 3]}><BestExecution /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/governance' element={<ProtectedRoutes role={[1, 2, 3]}><Governance /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/transactions-monitoring' element={<ProtectedRoutes role={[1, 2, 3]}><TransactionsMonitoring /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/users-cumulative-expenses' element={<ProtectedRoutes role={[1, 2, 3]}><CumulativeExpenses /></ProtectedRoutes>} />
        <Route exact path='/reports/compliance/users-traded' element={<ProtectedRoutes role={[1, 2, 3]}><UsersTraded /></ProtectedRoutes>} />

        <Route exact path='/reports/agbk-compliance' element={<ProtectedRoutes role={[1, 2, 3]}><ComplianceReportsAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/agbk-compliance/best-execution' element={<ProtectedRoutes role={[1, 2, 3]}><BestExecutionAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/agbk-compliance/economic-profile' element={<ProtectedRoutes role={[1, 2, 3]}><EconomicProfileAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/agbk-compliance/governance' element={<ProtectedRoutes role={[1, 2, 3]}><GovernanceAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/agbk-compliance/transactions-monitoring' element={<ProtectedRoutes role={[1, 2, 3]}><TransactionsMonitoringAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/agbk-compliance/users-cumulative-expenses' element={<ProtectedRoutes role={[1, 2, 3]}><CumulativeExpensesAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/agbk-compliance/sumsub' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubAgbkReports /></ProtectedRoutes>} />


        <Route exact path='/reports/accounts' element={<ProtectedRoutes role={[1, 2, 3]}><AccountsReports /></ProtectedRoutes>} />
        <Route exact path='/reports/accounts/funds-movements' element={<ProtectedRoutes role={[1, 2, 3]}><FundsMovements /></ProtectedRoutes>} />
        <Route exact path='/reports/accounts/agbk-funds-movements' element={<ProtectedRoutes role={[1, 2, 3]}><FundsMovementsAgbk /></ProtectedRoutes>} />
        <Route exact path='/reports/accounts/users-positions' element={<ProtectedRoutes role={[1, 2, 3]}><UsersPositions /></ProtectedRoutes>} />
        <Route exact path='/reports/accounts/users-wallet' element={<ProtectedRoutes role={[1, 2, 3]}><UsersWallet /></ProtectedRoutes>} />


        <Route exact path='/reports/sumsub-screening' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubScreeningReports /></ProtectedRoutes>} />
        <Route exact path='/reports/sumsub-screening/:type' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubScreeningFolderList /></ProtectedRoutes>} />
        <Route exact path='/reports/sumsub-screening/:type/:folder' element={<ProtectedRoutes role={[1, 2, 3]}><SumsubScreeningReportList /></ProtectedRoutes>} />

        <Route exact path='/reports/trs' element={<ProtectedRoutes role={[1, 2, 3]}><TransactionsReporting /></ProtectedRoutes>} />

        {/* Trade Reports */}
        <Route exact path='/reports/trade/volume-de-trade' element={<ProtectedRoutes role={[1, 2, 3]}><TradeVolume /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/waiting-trade-volume' element={<ProtectedRoutes role={[1, 2, 3]}><WaitingTradeVolume /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/volume-per-stock-list' element={<ProtectedRoutes role={[1, 2, 3]}><VolumePerStockList /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/volume-per-stock' element={<ProtectedRoutes role={[1, 2, 3]}><VolumePerStock /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/volume-per-commodity-timeframe' element={<ProtectedRoutes role={[1, 2, 3]}><VolumePerCommodityOnTimeframe /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/volume-per-commodity' element={<ProtectedRoutes role={[1, 2, 3]}><VolumePerCommodity /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/in-trade' element={<ProtectedRoutes role={[1, 2, 3]}><VolumeInTrade /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/available' element={<ProtectedRoutes role={[1, 2, 3]}><AvailableMoney /></ProtectedRoutes>} />
        <Route exact path='/reports/trade/card-fees' element={<ProtectedRoutes role={[1, 2, 3]}><CardFees /></ProtectedRoutes>} />

        {/* User Reports */}
        <Route exact path='/reports/users/most-important' element={<ProtectedRoutes role={[1, 2, 3]}><MostImportantClients /></ProtectedRoutes>} />
        <Route exact path='/reports/users/new' element={<ProtectedRoutes role={[1, 2, 3]}><NewClients /></ProtectedRoutes>} />
        <Route exact path='/reports/users/volume/average' element={<ProtectedRoutes role={[1, 2, 3]}><AverageVolumePerClient /></ProtectedRoutes>} />
        <Route exact path='/reports/users/gifts' element={<ProtectedRoutes role={[1, 2, 3]}><ReportGifts /></ProtectedRoutes>} />

        {/* User Reports */}
        <Route exact path='/reports/money/profits' element={<ProtectedRoutes role={[1, 2, 3]}><Profits /></ProtectedRoutes>} />
        <Route exact path='/reports/money/profits-action' element={<ProtectedRoutes role={[1, 2, 3]}><ProfitsPerStock /></ProtectedRoutes>} />

        {/* Users */}
        <Route exact path='/users/list' element={<ProtectedRoutes role={[1, 2, 3]}><Users /></ProtectedRoutes>} />
        <Route exact path='/users/call/list' element={<ProtectedRoutes role={[1, 2, 3]}><UsersToCall /></ProtectedRoutes>} />
        <Route exact path='/users/deleted/list' element={<ProtectedRoutes role={[1, 2, 3]}><DeletedUsers /></ProtectedRoutes>} />
        <Route exact path='/users/analytics' element={<ProtectedRoutes role={[1, 2, 3]}><Analytics /></ProtectedRoutes>} />
        <Route exact path='/user/:id' element={<ProtectedRoutes role={[1, 2, 3]}><UserById /></ProtectedRoutes>} />
        <Route exact path='/users/creators' element={<ProtectedRoutes role={[1, 2, 3]}><Creators /></ProtectedRoutes>} />
        <Route exact path='/users/creators/category/:id' element={<ProtectedRoutes role={[1, 2, 3]}><CreatorsByCategory /></ProtectedRoutes>} />
        <Route exact path='/users/notify' element={<ProtectedRoutes role={[1, 2, 3]}><UserNotify /></ProtectedRoutes>} />


        {/* AGO */}
        <Route exact path='/ago/users' element={<ProtectedRoutes role={[1, 2, 3]}><AgoUsers /></ProtectedRoutes>} />
        <Route exact path='/ago/user/:id' element={<ProtectedRoutes role={[1, 2, 3]}><AgoUser /></ProtectedRoutes>} />
        <Route exact path='/ago/deposits' element={<ProtectedRoutes role={[1, 2, 3]}><AgoDeposits /></ProtectedRoutes>} />
        <Route exact path='/ago/payouts' element={<ProtectedRoutes role={[1, 2, 3]}><AgoPayouts /></ProtectedRoutes>} />
        <Route exact path='/ago/transactions' element={<ProtectedRoutes role={[1, 2, 3]}><AgoTransactions /></ProtectedRoutes>} />
        <Route exact path='/ago/transaction/:id' element={<ProtectedRoutes role={[1, 2, 3]}><AgoTransactionById /></ProtectedRoutes>} />

        {/* Queues */}
        <Route exact path='/queues/:name' element={<ProtectedRoutes role={[1, 2, 3]}><Queues /></ProtectedRoutes>} />

        {/* Forms */}
        <Route exact path='/forms' element={<ProtectedRoutes role={[1, 2, 3]}><Forms /></ProtectedRoutes>} />

        {/* Structured products */}
        <Route exact path='/structured-products' element={<ProtectedRoutes role={[1, 2, 3]}><Structured /></ProtectedRoutes>} />
        <Route exact path='/structured-products/new' element={<ProtectedRoutes role={[1, 2, 3]}><PsInfos /></ProtectedRoutes>} />
        <Route exact path='/structured-products/:id/infos' element={<ProtectedRoutes role={[1, 2, 3]}><PsInfos /></ProtectedRoutes>} />
        <Route exact path='/structured-products/:id/financement' element={<ProtectedRoutes role={[1, 2, 3]}><PsFinances /></ProtectedRoutes>} />
        <Route exact path='/structured-products/:id/sous-jaccent' element={<ProtectedRoutes role={[1, 2, 3]}><SubJacent /></ProtectedRoutes>} />
        <Route exact path='/structured-products/:id/texts' element={<ProtectedRoutes role={[1, 2, 3]}><PsTexts /></ProtectedRoutes>} />
        <Route exact path='/structured-products/:id/dates' element={<ProtectedRoutes role={[1, 2, 3]}><Dates /></ProtectedRoutes>} />
        <Route exact path='/structured-products/:id/recap' element={<ProtectedRoutes role={[1, 2, 3]}><Recap /></ProtectedRoutes>} />

      </Routes>
    </div>
  );
}

export default App;
