// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paradox_sp_details_box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px 5px;
  width: 50%;
  position: relative;
}

.paradox_sp_details_title {
  align-self: center;
}

.paradox_sp_box {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 5px 5px;
}

.paradox_sp_column {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px
}

.paradox_sp_column_right {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 10px
}

.paradox_sp_search_box {
  width: 50%
}

.paradox_sp_search_input_box{
  border-radius: 5px;
  border: solid 1px #c2c2c2;
  padding: 10px 10px;
  width: 70%;
}

.paradox_sp_selected_product_composition_box{
  background-color: white;
  padding: 15px;
  border-radius: 12px;
}

.paradox_sp_delete_product_button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  color: black;
  border: none;
  border-radius: 50%;
  border: 2px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/paradox/builder/structured_products/paradoxSp.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV;AACF;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".paradox_sp_details_box {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 5px 5px;\n  width: 50%;\n  position: relative;\n}\n\n.paradox_sp_details_title {\n  align-self: center;\n}\n\n.paradox_sp_box {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n  padding: 5px 5px;\n}\n\n.paradox_sp_column {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  gap: 10px\n}\n\n.paradox_sp_column_right {\n  display: flex;\n  flex-direction: column;\n  width: 30%;\n  gap: 10px\n}\n\n.paradox_sp_search_box {\n  width: 50%\n}\n\n.paradox_sp_search_input_box{\n  border-radius: 5px;\n  border: solid 1px #c2c2c2;\n  padding: 10px 10px;\n  width: 70%;\n}\n\n.paradox_sp_selected_product_composition_box{\n  background-color: white;\n  padding: 15px;\n  border-radius: 12px;\n}\n\n.paradox_sp_delete_product_button {\n  position: absolute;\n  top: 15px;\n  right: 20px;\n  font-weight: bold;\n  width: 25px;\n  height: 25px;\n  color: black;\n  border: none;\n  border-radius: 50%;\n  border: 2px solid;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
