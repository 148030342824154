import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import './subscriptions.css'
import AuthContext from 'contexte/AuthContext';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { PARADOX_RELEASE_DATE } from 'utils/date';
import ScrollBar from 'react-perfect-scrollbar';
import { Box } from 'components/DataComponents/KPI/KpiBox/KpiBox';
import DateRangeSelector from 'components/Filters/DateSelector/DateSelector';
import { fetchSubscriptionsKpiDataForDates } from 'services/subscriptionsKpiServices';

const Subscriptions = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);


  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(PARADOX_RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [kpiData, setKpiData] = useState({
    selectedData: {
      subscriptions: 0,
      unsubscriptions: 0,
      new: 0,
      yearly: 0,
      mrr: 0
    },
    comparedData: {
      subscriptions: 0,
      unsubscriptions: 0,
      new: 0,
      yearly: 0,
      mrr: 0
    }
  });



  const fetchData = async () => {
    try {
      const data = await fetchSubscriptionsKpiDataForDates(user, selectedDate.date1, selectedDate.date2)
      setKpiData(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [selectedDate])

  return (
    <DashboardLayout page={t("pages_name.transactions_reporting")}>
      <div>
        {!loading ? (
          <div>
            <div className='trs_titleComponent'>
              <div className='trs_filter_component'>
                <DateRangeSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} withComparaison={true} />

              </div>
              <hr />
            </div>

            <div className='kpi_container'>
              <Box
                title='Abonnements totaux'
                data={kpiData.selectedData.subscriptions}
                comparedData={kpiData.comparedData.subscriptions}
                link='/users'
              />
              <Box
                title='Abonnements annuels'
                data={kpiData.selectedData.yearly}
                comparedData={kpiData.comparedData.yearly}
                link='/users'
              />
              <Box
                title='Nouveaux Abonnements'
                data={kpiData.selectedData.new}
                comparedData={kpiData.comparedData.new}
                link='/users'
              />
              <Box
                title='Désabonnements'
                data={kpiData.selectedData.unsubscriptions}
                comparedData={kpiData.comparedData.unsubscriptions}
                link='/users'
              />
              <Box
                title='MRR'
                data={kpiData.selectedData.mrr}
                comparedData={kpiData.comparedData.mrr}
                unit='€'
                link='/users'
              />
            </div>
          </div>
        ) : (
          <div>Loading..</div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Subscriptions


const ModalTrsRejectedDetails = ({ isModalOpen, onClose, details, date }) => {

  const transformModalDataToTBody = (data) => {
    return data.map(item => {
      return {
        data: [
          item.Instrument,
          item.Error
        ]
      };
    });
  }

  const modalClass = isModalOpen ? 'trs_modal_details_overlay open' : 'trs_modal_details_overlay';

  const { t } = useTranslation()

  return (
    <div className={modalClass}>
      <div className="trs_modal_details_container">
        <button className="close_button" onClick={onClose}>
          X
        </button>
        <h2 className='trs_modal_details_title'>{t("pages.transactions_reporting.modal.title", { date })}</h2>
        <div className="trs_details_details">
          <ScrollBar className='trs_modal_details_scrollbar'>
            <Tableau
              tbody={transformModalDataToTBody(details)}
              thead={['Instrument', 'Error']}
            />
          </ScrollBar>
        </div>
        <div className='trs_modal_details_button_div'>
          <button className='close' onClick={onClose}>{t("pages.transactions_reporting.modal.close")}</button>
        </div>
      </div>
    </div>
  )
}