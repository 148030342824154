import { useState, useEffect, useContext, useCallback } from 'react'
import AuthContext from 'contexte/AuthContext'
import './notificationBuilder.css'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { searchUsers } from 'services/fetchUsers'
import { fetchTickers } from 'services/tickersServices'
import SearchUser from 'components/SearchUser/SearchUser'
import { fetchLastUserPosts } from 'services/postServices'
import { fetchStructuredProducts } from 'services/structuredProductsServices'
import SearchStructuredProducts from 'components/SearchStructuredProducts/SearchStructuredProducts'
import { notifyUsers } from 'services/userServices'
import SearchTicker from 'components/SearchTicker/SearchTicker'
import DateRangeSelector from 'components/Filters/DateSelector/DateSelector'
import { useNavigate, useParams } from 'react-router-dom'
import { createNotification, fetchNotification, updateNotification } from 'services/notificationsServices'

const buildEmptyNotification = () => ({
  title: '',
  message: '',
  type: '',
  isSettable: false,
  ticker: {},
  product: {},
  owner: {},
  post: {}
})


const NotificationBuilder = () => {


  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [tickers, setTickers] = useState([]);

  // const [selectedUser, setSelectedUser] = useState({});
  // const [selectedPost, setSelectedPost] = useState({});
  // const [selectedTicker, setSelectedTicker] = useState({});
  // const [selectedSp, setSelectedSp] = useState({});
  const [loading, setLoading] = useState(false);
  // const [type, setType] = useState('');
  // const [title, setTitle] = useState('');
  const [posts, setPosts] = useState([]);
  const [structuredProducts, setStructuredProducts] = useState([]);
  // const [message, setMessage] = useState('');
  // const [isSettable, setIsSettable] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(), endDate: null },
    date2: { startDate: null, endDate: null },
  });
  const [isValid, setIsValid] = useState(false);

  const [notification, setNotification] = useState(buildEmptyNotification());

  const clearForm = () => {
    // setSelectedTicker({})
    // setSelectedUser({})
    // setSelectedSp({})
    // setSelectedPost({})
    // setType('')
    // setTitle('')
    // setMessage('')
    // setUsers([])
    // setTickers([])
    // setIsSettable(false)
    // setSelectedDate({
    //   date1: { startDate: new Date(), endDate: new Date() },
    //   date2: { startDate: null, endDate: null }
    // })
  }

  const handleInputChange = (field, value, additionalModifications) => {
    // console.log('field', field);
    // console.log('value', value);
    const types = ['product', 'ticker', 'owner', 'simple'];
    const updatedNotif = { ...notification, [field]: value };
    const index = types.indexOf(field);
    if (index > -1) {
      types.splice(index, 1);
      for (let type of types) {
        updatedNotif[type] = {}
      }
      if (field === 'owner') setPosts([])
    }
    // if (additionalModifications) {
    //   for (let modif of additionalModifications) {
    //     updatedNotif[modif['field']] = modif['value']
    //   }
    // }
    // updatedNotif[field] = value
    // console.log({ ...notification, [field]: value })
    setNotification(updatedNotif)
  }

  const notify = async (isBroadcast = false) => {
    try {
      // e.preventDefault()
      setLoading(true);
      const body = {
        type: notification.type,
        title: notification.title,
        message: notification.message,
        isBroadcast
      }
      if (notification.isSettable) {
        const date = new Date(selectedDate.date1.startDate);
        date.setUTCSeconds(0, 0);
        body['date'] = date
      }
      if (notification.type === 'post') {
        body['owner_uuid'] = notification.owner.uuid
        body['post_uuid'] = notification.post.getstream_uuid
      } else if (notification.type === 'stock') {
        body['ticker'] = notification.ticker.ticker
      } else if (notification.type === 'structured_product') {
        body['product_id'] = notification.product._id
      }
      if (id) {
        await updateNotification(user, id, body)
        launchSuccess(t('notifications.notifications.update.success'))
        navigate('/notifications');
      } else {
        await createNotification(user, body)
        launchSuccess(t('notifications.notifications.create.success'))
        if (isBroadcast)
          setNotification(buildEmptyNotification())
      }

      // if (isBroadcast)
      //   clearForm()
    } catch (e) {
      console.error(e);
      if (id)
        launchError(t('notifications.notifications.update.error'))
      else
        launchError(t('notifications.notifications.create.error'))
    } finally {
      setLoading(false)
    }
  }


  const fetchNotif = async () => {
    try {
      const data = await fetchNotification(user, id);
      if (data.date) {
        setSelectedDate({
          date1: { startDate: new Date(data.date), endDate: null },
          date2: { startDate: null, endDate: null },
        })
        data['isSettable'] = true
      }

      setNotification(data);
    } catch (error) {
      launchError(t('notifications.users.notify.error'))
    }
  }

  useEffect(() => {
    if (id) {
      fetchNotif()
    }
  }, [id])


  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const data = await fetchLastUserPosts(user, notification.owner?.uuid)
        setPosts(data);
        handleInputChange('post', data?.[0] || {})
      } catch (error) {
        launchError();
        setPosts([]);
      }
    }
    if (notification.owner?.uuid) {
      fetchUserPosts();
    }
  }, [user, notification.owner])


  useEffect(() => {
    const valid = notification.title && notification.message && (!notification.isSettable || (notification.isSettable && selectedDate.date1.startDate > new Date())) && (
      (notification.type === 'post' && notification.owner?.uuid && notification.post) ||
      (notification.type === 'stock' && notification.ticker?.id) ||
      (notification.type === 'structured_product' && notification.product?._id) ||
      (notification.type === 'simple')
    )
    setIsValid(valid)
  }, [notification.title, notification.message, notification.type, notification.post, notification.product, notification.owner, notification.ticker, selectedDate.date1.startDate])



  const handleSearchUsers = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchUsers = await searchUsers(user, search)
        setUsers(fetchUsers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setUsers([])
    }
  }

  const handleSearchTickers = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchedTickers = await fetchTickers({ user, page: 0, limit: 10, search })
        setTickers(fetchedTickers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setTickers([])
    }
  }

  const handleSearchSp = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const data = await fetchStructuredProducts({ user, page: 0, limit: 10, search })
        setStructuredProducts(data)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setStructuredProducts([])
    }
  }

  const handlePostWriter = (writer) => {
    handleInputChange('owner', writer, [{ field: 'post', value: {} }]);
  }

  const getComponent = (type) => {
    switch (type) {
      case 'post':
        return (
          <div>
            <div className='user_notify_item_box'>
              <label htmlFor='user-search'>Rédacteur</label>
              <SearchUser
                id='user-search'
                selectUser={handlePostWriter}
                selectedUser={notification.owner}
                users={users}
                handleSearch={handleSearchUsers}
              />
            </div>
            {notification.owner?.uuid && <div className='user_notify_item_box'>
              <label htmlFor="user-posts-select">10 derniers posts de {notification.owner?.firstName} {notification.owner?.lastName}</label>
              <select name="user-posts"
                className='user_notify_custom_select'
                id="user-posts-select"
                // value={index}
                onChange={(e) => handleInputChange('post', posts[e.target.value])}>
                {posts.map((post, index) => (<option key={index} value={index}>{post.desc}</option>))}
              </select>
            </div>}
          </div>

        )
      case 'stock':
        return (
          <div className='user_notify_item_box'>
            <label htmlFor="search_ticker">Ticker</label>
            <SearchTicker
              id='search_ticker'
              // setSelectedTicker={setSelectedTicker}
              setSelectedTicker={(value) => handleInputChange('ticker', value)}
              selectedTicker={notification.ticker}
              tickers={tickers}
              handleSearch={handleSearchTickers}
            />

          </div>
        )
      case 'structured_product':
        return (
          <div className='user_notify_item_box'>
            <label htmlFor="search_sp">Nom du produit structuré</label>
            <SearchStructuredProducts
              id='search_sp'
              setSelectedSp={(value) => handleInputChange('product', value)}
              selectedSp={notification.product}
              structuredProducts={structuredProducts}
              handleSearch={handleSearchSp}
            />

          </div>
        )
      default:
        return;
    }
  }



  return (
    <DashboardLayout page={t("pages_name.alpaca")}>
      <div>
        <Notification />
        <div>
          <div className='newGift_titleComponent'>
            <h2>Notifier les utilisateurs</h2>
          </div>
          <div>
            {/* <form onSubmit={handleSubmit}> */}
            <div className='user_notify_item_box'>
              <label htmlFor="notification-type-select">Type</label>
              <select name="notification-type"
                className='user_notify_custom_select'
                id="notification-type-select"
                value={notification.type}
                onChange={(e) => handleInputChange('type', e.target.value)}>
                <option value="">--Choisissez un type--</option>
                <option value="simple">Ne rien lier</option>
                <option value="post">Lier un post</option>
                <option value="structured_product">Lier un produit structuré</option>
                <option value="stock">Lier une action</option>
              </select>
            </div>
            <div className='user_notify_item_box'>
              <label htmlFor="title">Titre</label>
              <input
                id="title"
                className='user_notify_input'
                type="text"
                value={notification.title}
                onInput={(e) => handleInputChange('title', e.target.value)} />
            </div>
            <div className='user_notify_item_box'>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                className='user_notify_input'
                type="text"
                value={notification.message}
                onInput={(e) => handleInputChange('message', e.target.value)} />
            </div>
            {getComponent(notification.type)}
            <div className='user_notify_item_box'>
              <label htmlFor="programmable">Définir une d'envoi future</label>
              <label className="user_notify_toggle">
                <input className="user_notify_toggle_checkbox"
                  type="checkbox"
                  id="programmable"
                  checked={notification.isSettable}
                  onChange={(e) => handleInputChange('isSettable', e.target.checked)}
                />
                <div className="user_notify_toggle_switch"></div>
              </label>

            </div>
            {notification.isSettable && <div className='user_notify_item_box'>
              <label htmlFor="date_selector">Heure d'envoi</label>
              <DateRangeSelector
                id='date_selector'
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                withComparaison={false}
                simpleDate={true}
                showTime={true}
                minDate={new Date()}
              />
            </div>}
            <div className='user_notify_submit_box'>
              <button onClick={() => notify()} className='user_notify_button_test' disabled={!isValid} >Tester</button>
              <button onClick={() => notify(true)} className='user_notify_button_submit' disabled={!isValid} >Envoyer à tout le monde</button>
            </div>
            {/* </form> */}
          </div>
        </div>

      </div>
    </DashboardLayout>
  )
}

export default NotificationBuilder