import { useState, useEffect, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './payouts.css'
import AuthContext from '../../../../contexte/AuthContext';
import './components/payoutItem/payoutItem.css';
import Modal from 'components/modal/Modal';
import { approveRejectPendingPayout } from 'services/payoutServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import ResponseContext from 'contexte/ToasterContext';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { formatDate } from 'utils/date';
import useFilters from 'hooks/useFilters';
import Notification from 'components/notifications/Notification';
import { launchError, launchSuccess } from 'components/notifications';
import { fetchPendingPayouts } from 'services/fetchPayouts';
import { formatIBAN } from 'utils/iban';

const Payouts = () => {

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const initialFilters = {
    fetchLimit: 10,
    page: 0,
    search: ''
  };

  const { filters, handleFiltersChange, resetFilters } = useFilters(initialFilters);

  const setFetchLimit = (newLimit) => handleFiltersChange('fetchLimit', newLimit);
  const setPage = (newPage) => handleFiltersChange('page', newPage);
  let searchTimeout
  const setSearch = (newSearch) => {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      handleFiltersChange('search', newSearch);
      handleFiltersChange('page', 0);
    }, 300)
  }


  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  const [modalError, setModalError] = useState("")
  const [pendingPayouts, setPendingPayouts] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
      const payouts = await fetchPendingPayouts({
        user,
        page: filters.page,
        limit: filters.fetchLimit,
        search: filters.search
      });
      setPendingPayouts(payouts);
    }
    try {
      setLoading(true);
      fetchData();
    } catch (e) {
      launchError(t('notifications.payouts.pendingPayouts.fetch.error'));
    } finally {
      setLoading(false);
    }

  }, [filters.page, filters.fetchLimit, filters.search])

  const onClickFunction = (index) => {
    setModalIndex(index)
    setIsModalOpen(true);
  }

  const transformDatatoTbody = (data) => {
    return data.map(item => {
      return {
        data: [
          formatDate({ date: item.created_at, time: true }),
          `${item.firstName} ${item.lastName}`,
          formatIBAN(item.iban),
          item.amount,
        ]
      }
    })
  }


  const approveRejectPayout = async (type) => {
    try {
      await approveRejectPendingPayout(user, type, pendingPayouts[modalIndex].withdraw_id)
      launchSuccess(t(`notifications.payouts.pendingPayouts.${['launch', 'manual'].includes(type) ? 'approve' : 'reject'}.success`));
      setPendingPayouts(prevState => {
        const newState = [...prevState]
        newState.splice(modalIndex, 1)
        return newState;
      });
      setIsModalOpen(false);
      setModalIndex(-1);
    } catch (e) {
      launchError(t(`notifications.payouts.pendingPayouts.${['launch', 'manual'].includes(type) ? 'approve' : 'reject'}.error`));
      console.error(e)
    }
  }

  if (loading) return (
    <div className='pageLoaderBox'>
      <PageLoader />
    </div>
  )

  return (
    <DashboardLayout page={t("pages_name.payouts")}>
      <div>
        <Notification />
        <div>
          <div className='payouts_titleComponent'>
            <h2>{t('pages.payouts.title')}</h2>
          </div>
          <FilterComponent
            resetFilter={resetFilters}
            limit={true}
            fetchLimit={filters.fetchLimit}
            setFetchLimit={setFetchLimit}
            withSearch
            search={filters.search}
            setSearch={setSearch}
            searchPlaceHolder={'Entrez le nom ou prénom'}
          />
          <div className="table-container">
            <div className="table-column">
              {pendingPayouts?.length && (
                <Tableau
                  thead={[t("pages.payouts.date"), t("pages.payouts.reference"), t("pages.payouts.iban"), t("pages.payouts.amount")]}
                  tbody={transformDatatoTbody(pendingPayouts)}
                  hadModal={true}
                  hadLink={false}
                  onClickFunction={onClickFunction}
                  page={filters.page} setPage={setPage}
                  pagination={true}
                  limit={filters.fetchLimit}
                />
              )}
            </div>
          </div>

          {
            isModalOpen && (
              <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeButton={false} >
                <h2 className='modal_title'>{t("pages.payouts.modal.title")}</h2>
                <div className="payout-details">
                  {
                    pendingPayouts[modalIndex].isDeleted === 1 && (
                      <div className='warning_deleted'>
                        <img src="https://assets-global.website-files.com/612cdb668b06dbb2a51f99ee/651e6e6668d310d564622c79_warning_img.png" alt="" width={25} />
                        <span>{t("pages.payouts.modal.user_deleted")}</span>
                      </div>
                    )
                  }
                  <p><strong>{t("pages.payouts.modal.name")}</strong> {pendingPayouts[modalIndex].firstName} {pendingPayouts[modalIndex].lastName}</p>
                  <p><strong>{t("pages.payouts.modal.phone")}</strong> {pendingPayouts[modalIndex].phone}</p>
                  <p><strong>{t("pages.payouts.modal.adress")}</strong> {pendingPayouts[modalIndex].complete_address}</p>
                  <p><strong>{t("pages.payouts.modal.birthday")}</strong> {new Date(pendingPayouts[modalIndex].birthDate).toLocaleDateString()}</p>
                  <p><strong>{t("pages.payouts.modal.bankName")}</strong> {pendingPayouts[modalIndex].bank_name || 'N/A'}</p>
                  <p><strong>{t("pages.payouts.modal.iban")}</strong> {pendingPayouts[modalIndex].iban || 'N/A'}</p>
                </div>
                {
                  modalError !== "" && (
                    <div className='warning_deleted'>
                      <img src="https://assets-global.website-files.com/612cdb668b06dbb2a51f99ee/651e6e6668d310d564622c79_warning_img.png" alt="" width={25} />
                      <span>{modalError}</span>
                    </div>
                  )
                }
                <hr className='modal_hr_button' />
                <div className='modal-button-div'>
                  <button className='close' onClick={() => setIsModalOpen(false)}>{t("pages.payouts.buttons.close")}</button>
                  <button className='valider' onClick={() => { approveRejectPayout('launch') }}>{t('pages.payouts.buttons.accept')}</button>
                  <button className='refuser' onClick={() => { approveRejectPayout('refuse') }}>{t('pages.payouts.buttons.refuse')}</button>
                  <button className='manual' onClick={() => { approveRejectPayout('manual') }}>{t('pages.payouts.buttons.manual')}</button>
                </div>
              </Modal>
            )
          }
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Payouts

