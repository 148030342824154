// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.paradox_infos_search_ticker_picture {
  width: 32px;
  height: 32px;
}



.paradox_infos_search_ticker_container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 15px;
  width: 50%;
}

.paradox_infos_search_ticker_container input {
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 4px;
  width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.paradox_infos_search_ticker_list_box {
  position: absolute;
  background-color: white;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 10px;
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 90%;
}

.paradox_infos_ticker_search_input {
  position: relative;
}

.paradox_infos_relative {
  position: relative;
}

.paradox_infos_search_ticker_item_select_ticker {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}`, "",{"version":3,"sources":["webpack://./src/pages/paradox/builder/infos/paradoxInfos.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,YAAY;AACd;;;;AAIA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB;AACJ","sourcesContent":["\n.paradox_infos_search_ticker_picture {\n  width: 32px;\n  height: 32px;\n}\n\n\n\n.paradox_infos_search_ticker_container {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  gap: 15px;\n  width: 50%;\n}\n\n.paradox_infos_search_ticker_container input {\n  max-width: 100%;\n  border: 1px solid #ccc;\n  padding: 10px 15px;\n  border-radius: 4px;\n  width: 100%;\n}\n\nul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n\n.paradox_infos_search_ticker_list_box {\n  position: absolute;\n  background-color: white;\n  max-width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  max-height: 150px;\n  overflow-y: scroll;\n  width: 100%;\n  top: 100%;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  padding: 10px;\n  border: solid 1px #ccc;\n  border-radius: 4px;\n  width: 90%;\n}\n\n.paradox_infos_ticker_search_input {\n  position: relative;\n}\n\n.paradox_infos_relative {\n  position: relative;\n}\n\n.paradox_infos_search_ticker_item_select_ticker {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 10px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
