import PsLayout from "pages/structured/layout/PsLayout"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { fetchTickers } from "services/tickersServices"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../builder.css"
import { fetchSpDraft, saveSpDraft } from "services/spDraftServices"

const SubJacent = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [searchedTickers, setSearchedTickers] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(product);
    try {
      const response = await saveSpDraft(user, window.location.pathname.split('/')[2], product);
      navigate(`/structured-products/${window.location.pathname.split('/')[2]}/texts`)

    } catch (err) {
      console.log(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchSpDraft(user, window.location.pathname.split('/')[2]);
      setProduct({
        ...response,
        product_type: response.product_type || 'worst-of'
      })
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const handleSearcchTicker = async (e) => {
    try {
      const response = await fetchTickers({ user, page: 0, limit: 10, search: e.target.value })
      setSearchedTickers(response)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSelectTicker = (selectedTicker) => {
    const currentProducts = product.products ? [...product.products] : [];
    if (!currentProducts.some(ticker => ticker.ticker === selectedTicker.ticker)) {
      currentProducts.push(selectedTicker);
    }
    setProduct({
      ...product,
      products: currentProducts
    });
  }


  const handleRemoveTicker = (tickerSymbol) => {
    const updatedProducts = product.products.filter(ticker => ticker.ticker !== tickerSymbol);
    setProduct({
      ...product,
      products: updatedProducts
    });
  }



  return (
    <PsLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="select-ticker-flex">
                <div className="select-ticker-search">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Produits associés :</span>
                    <input className="psform-input-text" type="text" name="products" placeholder="Rechercher un ticker" onChange={handleSearcchTicker} />
                  </div >
                  {
                    searchedTickers.length > 0 && (
                      <div className="ticker-results">
                        {searchedTickers.map((ticker, index) => (
                          <div key={index} className="psform-ticker-box pointer" onClick={() => handleSelectTicker(ticker)}>
                            <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} alt="" />
                            <span className="psform-input-label">{ticker.name}</span>
                          </div >
                        ))}
                      </div>
                    )
                  }
                </div>
                <div className="select-ticker-product">
                  {
                    product.products.map((ticker, index) => (
                      <div key={index} className="psform-ticker-selected-box pointer">
                        <div className="ticker-desc">
                          <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} alt="" />
                          <span className="psform-input-label">{ticker.name}</span>
                        </div>
                        <span onClick={() => handleRemoveTicker(ticker.ticker)} className="pointer delete-ticker-button">supprimer</span>
                      </div >
                    ))
                  }
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Barrière de protection (ajouter un nombre négatif):</span>
                <div>
                  <input className="psform-input-text" type="number" name="barrier_capital" defaultValue={product.barrier_capital} onChange={handleInputChange} />
                  <span>%</span>
                </div>
              </div >
              <div className="psform-input-box">
                <span className="psform-input-label">Type :</span>
                <select name="product_type" id="product_type" className="psform-input-text" defaultValue={product.product_type} onChange={handleInputChange}>
                  <option value="worst-of">Worst of</option>
                  { /* Ajouter les autres que je connais pas */}
                </select>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </PsLayout>
  )
}

export default SubJacent