import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { searchEtfsByName } from "services/tickersServices"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../paradoxBuilder.css"
import { fetchParadoxProduct, updateParadoxProduct } from "services/paradoxServices";
import { useParams } from "react-router-dom";
import ParadoxLayout from "pages/paradox/layout/ParadoxLayout";

const ParadoxEtfs = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [searchedEtfs, setSearchedEtfs] = useState([]);
  const [search, setSearch] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateParadoxProduct(user, id, product);
      navigate(`/paradox/${id}/structured-product`)

    } catch (err) {
      console.error(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchParadoxProduct(user, id);
      setProduct({
        ...response,
        product_type: response.product_type || 'worst-of'
      })
      setLoading(false)
    } catch (e) {
      console.error(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleSearchEtf = async (e) => {
    try {
      setSearch(e.target.value)
      const response = await searchEtfsByName(user, e.target.value)
      setSearchedEtfs(response)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSelectEtf = (selectedEtf) => {
    setSearch('')
    const currentEtfs = product.etf ? [...product.etf] : [];
    if (!currentEtfs.some(({ ticker }) => ticker === selectedEtf.ticker)) {
      currentEtfs.push(selectedEtf);
    }
    setProduct({
      ...product,
      etf: currentEtfs
    });
  }


  const handleRemoveTicker = (etf) => {
    const updatedEtfs = product.etf.filter(({ ticker }) => ticker !== etf.ticker);
    setProduct({
      ...product,
      etf: updatedEtfs
    });
  }



  return (
    <ParadoxLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="select-ticker-flex">
                <div className="paradox_etfs_search_box">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Produits associés :</span>
                    <input className="paradox_etfs_search_input_box" type="text" name="products" placeholder="Rechercher un ETF" onChange={handleSearchEtf} value={search} />
                  </div >
                  {
                    searchedEtfs.length > 0 && search.length > 0 && (
                      <div className="ticker-results">
                        {searchedEtfs.map((etf, index) => (
                          <div key={index} className="psform-ticker-box pointer" onClick={() => handleSelectEtf(etf)}>
                            <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${etf.ticker}.png`} alt="" />
                            <span className="psform-input-label">{etf.name} | {etf.ticker}</span>
                          </div >
                        ))}
                      </div>
                    )
                  }
                </div>
                <div className="paradox_etfs_details_box">
                  {
                    product?.etf?.map((etf, index) => (
                      <div key={index} className="psform-ticker-selected-box pointer">
                        <div className="ticker-desc">
                          <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${etf.ticker}.png`} alt="" />
                          <div className="paradox_etfs_selected_item">
                            <span className="psform-input-label">{etf.name}</span>
                            <span className="psform-input-label-text-small">{etf.ticker}</span>
                          </div>

                        </div>
                        <span onClick={() => handleRemoveTicker(etf)} className="pointer delete-ticker-button">supprimer</span>
                      </div >
                    ))
                  }
                </div>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </ParadoxLayout>
  )
}

export default ParadoxEtfs